import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AnalyticsEventType } from '@irembo-andela/irembogov3-common';
import { LocaleService } from '../../../services/locale.service';
import { LOCALE_URL_MAPPING } from '../../../models/locale/locale-url-mapping.model';

@Component({
  selector: 'irembogov-portal-footer',
  templateUrl: './portal-footer.component.html',
  styleUrls: ['./portal-footer.component.scss'],
})
export class PortalFooterComponent implements OnInit {
  env = environment;
  currentYear = new Date().getFullYear();
  analyticsEventType = AnalyticsEventType;
  supportUrl: string = this.supportCenterUrl(
    this.localeService.activeLocaleSubject.getValue().locale
  );

  constructor(private localeService: LocaleService) {}

  ngOnInit(): void {
    this.localeService.activeLocale$.subscribe(locale => {
      this.supportUrl = this.supportCenterUrl(locale.locale);
    });
  }

  supportCenterUrl(locale: string): string {
    const urlParam =
      LOCALE_URL_MAPPING[locale as keyof typeof LOCALE_URL_MAPPING] || 'en';
    return this.env.knowledgeBaseUrl(urlParam);
  }
}
