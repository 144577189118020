import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { Subscription, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { IdInputStatusEnum } from '../../../models/irembo-id-input-status.enum';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { IEnvironment } from '../../../models/environment.model';
import {
  resetFieldsToPopulate,
  updateUrlWithApiGatewayBaseUrl,
  getPopulateReferenceForm,
  populateFormFields,
  subscribeToResetFieldFetchData,
  updateUrlWithMockBaseUrl,
} from '../../../../utils/utils/data-fetch-widget-utils';
import { IHttpSingleDataResponse } from '../../../../utils/models/http-single-data-response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  CustomIdInputTypesApiUrls,
  ECustomIdInputErrorKeys,
  IIdInputTypeApiUrls,
  TIdType,
} from '../../../config/custom-id-input-api-urls.config';
import { FormStateService } from '../../../services/formly/form-state.service';
import { EIremboFormlyFormStateKeys } from '../../../models/irembo-formly-formstate-keys.enum';

enum EVerificationMethods {
  NAME = 'NAME',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
}

@Component({
  selector: 'irembogov-custom-id-input',
  templateUrl: './custom-id-input.component.html',
  styleUrls: ['./custom-id-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CustomIdInputComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit, OnDestroy, AfterViewInit, AfterContentChecked
{
  private environment: IEnvironment;
  private subscriptions = new Subscription();

  isReadonly = false;

  statusClass: string | undefined;

  fieldsToPopulate: Record<string, string>[] = [];
  usePrefetch = false;

  endpointCode?: string = undefined;
  idType?: TIdType = undefined;
  idUrls?: IIdInputTypeApiUrls = undefined;
  idVerificationMethod?: EVerificationMethods = undefined;
  EVerificationMethods = EVerificationMethods;
  verifyingIdValue?: string = undefined;

  isValidatingIdOwner = false;

  firstOrLastName: FormControl = new FormControl('', [
    Validators.pattern(/^\S+(?:\s+\S+)*$/),
  ]);
  dateOfBirth: FormControl = new FormControl('');

  constructor(
    @Inject('environment') environment: IEnvironment,
    private http: HttpClient,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private formStateService: FormStateService,
    private translateService: TranslateService
  ) {
    super();
    this.environment = environment;
  }

  @ViewChild('idFetchVerificationModal')
  idFetchVerificationModal!: TemplateRef<any>;

  ngOnInit(): void {
    this.verifyingIdValue = undefined;
  }

  ngAfterViewInit(): void {
    this.setFieldOptionValues();

    this.subscriptions.add(
      subscribeToResetFieldFetchData(
        this.field,
        this.formStateService,
        this.fieldsToPopulate,
        getPopulateReferenceForm(this.field, this.form)
      )
    );

    this.subscriptions.add(
      this.field.formControl.valueChanges
        .pipe(
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
          )
        )
        .subscribe({
          next: () => {
            const isInvalid = this.field.formControl.invalid;
            if (isInvalid) {
              this.deleteFetchedDataKeyInFormState();
            }
            if (isInvalid && this.fieldsToPopulate.length > 0) {
              const populateForm: FormGroup<any> | FormArray<any> =
                getPopulateReferenceForm(this.field, this.form);
              resetFieldsToPopulate(this.fieldsToPopulate, populateForm);
            }
          },
        })
    );
  }

  ngAfterContentChecked() {
    this.generateErrorMessages();
  }

  setFieldOptionValues(): void {
    if (this.field.props?.['populates']) {
      if (!Array.isArray(this.field.props?.['populates'])) {
        throw new Error('populates should be an array');
      }
      this.fieldsToPopulate = this.field.props?.['populates'];
    }

    if (this.field.props?.['usePrefetch']) {
      this.usePrefetch = this.field.props?.['usePrefetch'];
    }

    if (this.field.props?.['idType']) {
      this.idType = this.field.props?.['idType'];
    }

    this.generateIdUrls();

    this.setEndPointCode();

    this.formControl.enable();

    if (
      this.usePrefetch &&
      this.idUrls?.privacy &&
      this.idUrls?.prefetchUrl &&
      this.idUrls?.prefetchFieldKey &&
      !this.environment.applicationByOther
    ) {
      this.isReadonly = true;
      this.getIdfromPrefetchUrl();
    } else {
      this.isReadonly = false;
    }
  }

  generateIdUrls() {
    const url: string | undefined = this.field.props?.['url'];

    if (
      this.idType === 'NID' ||
      (url && url.toLowerCase().indexOf('identity/v1/nid-info') > -1)
    ) {
      this.idType = 'NID';
      this.idUrls = CustomIdInputTypesApiUrls().nid;
      return;
    }

    if (
      this.idType === 'CHILD_ID' ||
      (url && url.toLowerCase().indexOf('identity/v1/child-id-info') > -1)
    ) {
      this.idType = 'CHILD_ID';
      this.idUrls = CustomIdInputTypesApiUrls().childId;
      return;
    }

    if (
      this.idType === 'NIN' ||
      (url && url.toLowerCase().indexOf('identity/v1/nin-info') > -1)
    ) {
      this.idType = 'NIN';
      this.idUrls = CustomIdInputTypesApiUrls().nin;
      return;
    }

    this.idUrls = {
      basic: url,
    };
  }

  setEndPointCode(): void {
    if (this.field.props?.['endpointCode']) {
      this.endpointCode = this.field.props?.['endpointCode'];
    }

    if (
      !this.endpointCode &&
      (this.idType === 'NID' || this.idType === 'CHILD_ID')
    ) {
      this.statusClass = IdInputStatusEnum.DANGER;
      this.setErrorOnFormControl(
        ECustomIdInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED
      );

      this.field.formControl.setValue(
        this.translateService.instant('CONFIGURATION ERROR!')
      );
      this.isReadonly = true;
      this.field.formControl.markAsTouched();

      this.cd.detectChanges();
      throw new Error(
        `An endpointCode is required for ${this.idType} components.`
      );
    }
  }

  generateErrorMessages(): void {
    const validationMessages: any = {};

    validationMessages[ECustomIdInputErrorKeys.ENDPOINT_CODE_NOT_CONFIGURED] =
      this.translateService.instant('Missing endpoint code in ID Component.');

    const defaultInvalidInputMessage = this.translateService.instant(
      `${this.field.props?.label ?? 'this field'} must be ${
        this.field.props['idLength']
      } digits.`
    );
    validationMessages[ECustomIdInputErrorKeys.INVALID_INPUT] =
      this.field.validation?.messages?.[
        ECustomIdInputErrorKeys.INVALID_INPUT
      ] ?? defaultInvalidInputMessage;

    const defaultInvalidIdInputMessage = this.translateService.instant(
      `Sorry, we can't find a matching ID for ${
        this.field.props?.label ?? 'this field'
      }. Please contact your ID provider for more details.`
    );
    validationMessages[ECustomIdInputErrorKeys.INVALID_ID_INPUT] =
      this.field.validation?.messages?.[
        ECustomIdInputErrorKeys.INVALID_ID_INPUT
      ] ?? defaultInvalidIdInputMessage;

    validationMessages[ECustomIdInputErrorKeys.VERIFICATON_FAILED] =
      this.translateService.instant(
        'ID verification failed. Please try again.'
      );

    validationMessages[ECustomIdInputErrorKeys.VERIFICATION_REQUIRED] =
      this.translateService.instant("Please verify ID owner's consent.");

    if (this.idType === 'NID') {
      validationMessages[ECustomIdInputErrorKeys.VERIFICATON_INVALID] =
        this.translateService.instant(
          "Details don't match NIDA records. Please check the I.D. owner's info and try again. If correct, contact NIDA."
        );
    } else {
      validationMessages[ECustomIdInputErrorKeys.VERIFICATON_INVALID] =
        this.translateService.instant(
          "Details don't match any record. Please check the I.D. owner's info and try again. If correct, contact your ID provider."
        );
    }

    if (!this.field.validation) {
      this.field.validation = {};
    }

    if (!this.field.validation?.messages) {
      this.field.validation.messages = {};
    }
    this.field.validation.messages = {
      ...this.field.validation.messages,
      ...validationMessages,
    };
    this.cd.detectChanges();
  }

  onVerifyId(citizenId: string): void {
    if (this.idUrls?.privacy) {
      this.requestDataByPrivacyUrl(citizenId);
      return;
    }
    this.requestDataByBasicIdUrl(citizenId);
  }

  getIdfromPrefetchUrl(): void {
    if (!(this.idUrls?.prefetchUrl && this.idUrls.prefetchFieldKey)) {
      throw new Error('Missing prefetch Url and prefetch field key.');
    }

    /**
     * No data fetch when in RFA mode and  not flagged in RFA
     */
    if (
      this.formStateService.checkIfApplicationInAnRFAState() &&
      !this.formStateService
        .getCurrentFormlyFormState()
        ?.[EIremboFormlyFormStateKeys.RFA_FLAGGED_FIELDS]?.includes(
          <string>this.field.key
        )
    ) {
      return;
    }

    const prefetchUrl = this.useBaseUrlHandler(this.idUrls.prefetchUrl);
    this.statusClass = IdInputStatusEnum.VERIFYING;
    this.subscriptions.add(
      this.http
        .get<IHttpSingleDataResponse<any>>(prefetchUrl, {
          headers: {},
        })
        .subscribe({
          next: (res: IHttpSingleDataResponse<any>) => {
            const isVerifiedByIdInPrefetch: boolean =
              res.data?.accountVerified &&
              res.data?.identificationType === this.idUrls?.prefetchFieldKey &&
              res?.data?.identificationNumber;

            /**
             * Get data via privacy url for verified user when not in RFA state
             */
            if (isVerifiedByIdInPrefetch) {
              this.field.formControl.setValue(res.data?.identificationNumber);
              this.requestDataByPrivacyUrl(res.data?.identificationNumber);
              return;
            }

            /**
             * Enable field without data fetch if in RFA mode and also flagged in RFA
             */
            if (this.formStateService.checkIfApplicationInAnRFAState()) {
              this.isReadonly = isVerifiedByIdInPrefetch
                ? true
                : !this.formStateService
                    .getCurrentFormlyFormState()
                    ?.[EIremboFormlyFormStateKeys.RFA_FLAGGED_FIELDS]?.includes(
                      <string>this.field.key
                    );

              this.statusClass = undefined;
              this.cd.detectChanges();
              return;
            }

            this.isReadonly = false;

            console.log('No verified ID in user profile.');

            if (this.field.formControl.value) {
              this.statusClass = IdInputStatusEnum.DANGER;
              this.setErrorOnFormControl(
                ECustomIdInputErrorKeys.VERIFICATION_REQUIRED
              );
            } else {
              this.statusClass = undefined;
            }

            this.cd.detectChanges();
          },
          error: () => {
            this.statusClass = IdInputStatusEnum.DANGER;
            this.setErrorOnFormControl(
              ECustomIdInputErrorKeys.VERIFICATON_FAILED
            );
            console.log('Can not fetch profile id from profile');
            this.cd.detectChanges();
          },
        })
    );
  }

  requestDataByBasicIdUrl(citizenId: string): void {
    if (!this.idUrls?.basic) {
      throw new Error('url property is required');
    }

    if (this.environment.applicationByOther) {
      const index = this.idUrls.basic.lastIndexOf('-by');
      const newUrl =
        index == -1 ? this.idUrls.basic : this.idUrls.basic.substring(0, index);
      this.idUrls.basic = newUrl;
    }

    const url = this.useBaseUrlHandler(this.idUrls.basic);

    this.deleteAllIDFieldErrorsOnFromControl();
    this.statusClass = IdInputStatusEnum.VERIFYING;
    this.subscriptions.add(
      this.http
        .get<Record<string, unknown>>(url, {
          headers: {
            'Citizen-Id': citizenId,
            'Endpoint-Code': this.endpointCode ?? '',
          },
        })
        .subscribe({
          next: (response: Record<string, unknown>) => {
            this.deleteAllIDFieldErrorsOnFromControl();
            this.handleFetchIdResponse(response);
          },
          error: () => {
            this.statusClass = IdInputStatusEnum.DANGER;
            this.setErrorOnFormControl(
              ECustomIdInputErrorKeys.INVALID_ID_INPUT
            );
            this.cd.detectChanges();
          },
        })
    );
  }

  requestDataByPrivacyUrl(profileId: string): void {
    if (!this.idUrls?.privacy) {
      throw new Error('Privacy url not configured for ID type');
    }

    const privacyUrl = this.useBaseUrlHandler(this.idUrls.privacy);
    this.deleteAllIDFieldErrorsOnFromControl();
    this.statusClass = IdInputStatusEnum.VERIFYING;
    this.subscriptions.add(
      this.http
        .get<Record<string, unknown>>(privacyUrl, {
          headers: {
            'Citizen-Id': profileId,
            'Endpoint-Code': this.endpointCode ?? '',
          },
        })
        .subscribe({
          next: (res: Record<string, unknown>) => {
            this.deleteAllIDFieldErrorsOnFromControl();
            this.handleFetchIdResponse(res);
          },
          error: (error: HttpErrorResponse) => {
            this.statusClass = IdInputStatusEnum.DANGER;
            if (error?.error?.message === 'USER_REQUIRE_VALIDATION') {
              this.setErrorOnFormControl(
                ECustomIdInputErrorKeys.VERIFICATION_REQUIRED
              );
              this.requestGetDataByVerifyUrl(profileId);
              return;
            }
            this.setErrorOnFormControl(
              ECustomIdInputErrorKeys.VERIFICATON_FAILED
            );
            this.cd.detectChanges();
          },
        })
    );
  }

  async requestGetDataByVerifyUrl(citizenId: string) {
    if (!this.idUrls?.verify) {
      throw new Error('url property is required');
    }
    let verifyUrl = this.idUrls.verify;
    const arr = new Uint32Array(1);
    crypto.getRandomValues(arr);
    const randomValue = arr[0] * Math.pow(2, -32);
    this.idVerificationMethod =
      randomValue < 0.5
        ? EVerificationMethods.DATE_OF_BIRTH
        : EVerificationMethods.NAME;

    if (this.field.props['useBaseUrl']) {
      verifyUrl = this.useBaseUrlHandler(this.idUrls.verify);
    }

    this.deleteAllIDFieldErrorsOnFromControl();
    try {
      this.firstOrLastName.reset();
      this.dateOfBirth.reset();
      this.verifyingIdValue = citizenId;

      const validateOwnerModalRef = this.modalService.open(
        this.idFetchVerificationModal,
        {
          ariaLabelledBy: 'modal-validate-id-fetch-title',
          centered: true,
          backdrop: 'static',
          modalDialogClass: 'modal-validate-id-fetch-dialog',
          windowClass: 'modal-validate-id-fetch-window',
          container: `#validate-id-fetch-modal-container-${this.field.key}`,
        }
      );
      const validateOwnerReq = await lastValueFrom(
        validateOwnerModalRef.closed
      );

      if (validateOwnerReq !== 'verifyId') {
        this.setErrorOnFormControl(
          ECustomIdInputErrorKeys.VERIFICATION_REQUIRED
        );
        throw new Error('Verification canceled.');
      }

      const verifyParams: Record<string, unknown> = {};

      if (this.idType === 'NID') {
        verifyParams['nid'] = citizenId;
      }

      if (this.idType === 'CHILD_ID') {
        verifyParams['childId'] = citizenId;
      }

      if (
        !(
          (this.dateOfBirth.value || this.firstOrLastName.value) &&
          this.dateOfBirth.valid &&
          this.firstOrLastName.valid
        )
      ) {
        this.verifyingIdValue = undefined;
        throw new Error(
          'Fill the required verification information correctly.'
        );
      }

      switch (this.idVerificationMethod) {
        case EVerificationMethods.DATE_OF_BIRTH: {
          verifyParams['verifierType'] = EVerificationMethods.DATE_OF_BIRTH;
          verifyParams['verifierValue'] = this.dateOfBirth.value;
          break;
        }
        case EVerificationMethods.NAME: {
          verifyParams['verifierType'] = EVerificationMethods.NAME;
          verifyParams['verifierValue'] = this.firstOrLastName.value;
          break;
        }
        default: {
          throw new Error('Invalid verification method.');
        }
      }

      this.statusClass = IdInputStatusEnum.VERIFYING;
      this.subscriptions.add(
        this.http
          .post<Record<string, unknown>>(verifyUrl, verifyParams, {
            headers: {
              'Citizen-Id': citizenId,
              'Endpoint-Code': this.endpointCode ?? '',
            },
          })
          .subscribe({
            next: (res: Record<string, unknown>) => {
              this.deleteAllIDFieldErrorsOnFromControl();
              this.handleFetchIdResponse(res);
              validateOwnerModalRef.close();
            },
            error: (error: HttpErrorResponse) => {
              this.statusClass = IdInputStatusEnum.DANGER;
              if (error?.error?.message === 'USER_REQUIRE_VALIDATION') {
                this.setErrorOnFormControl(
                  ECustomIdInputErrorKeys.VERIFICATON_INVALID
                );
              } else {
                this.setErrorOnFormControl(
                  ECustomIdInputErrorKeys.VERIFICATON_FAILED
                );

                throw new Error('Could not verify this ID.');
              }
              this.cd.detectChanges();
            },
          })
      );
    } catch (err: any) {
      if (err?.message) {
        console.log(err?.message);
      }
      this.cd.detectChanges();
    }
  }

  handleFetchIdResponse(response: Record<string, unknown>) {
    this.statusClass = IdInputStatusEnum.SUCCESS;
    const data = response['data'] as Record<string, unknown>;
    this.formStateService.saveFetchedDataKeyInFormState(this.field, data);
    if (this.fieldsToPopulate.length > 0) {
      const populateForm: FormGroup<any> | FormArray<any> =
        getPopulateReferenceForm(this.field, this.form);
      populateFormFields(data, this.field, populateForm);
    }
    this.cd.detectChanges();
  }

  deleteErrorOnFormControl(errorKey: string): void {
    delete this.field.formControl.errors?.[errorKey];
    this.field.formControl.updateValueAndValidity();
  }

  deleteAllIDFieldErrorsOnFromControl(): void {
    this.deleteErrorOnFormControl(
      ECustomIdInputErrorKeys.VERIFICATION_REQUIRED
    );
    this.deleteErrorOnFormControl(ECustomIdInputErrorKeys.VERIFICATON_FAILED);
    this.deleteErrorOnFormControl(ECustomIdInputErrorKeys.VERIFICATON_INVALID);
    this.deleteErrorOnFormControl(ECustomIdInputErrorKeys.INVALID_ID_INPUT);
    this.deleteErrorOnFormControl(ECustomIdInputErrorKeys.INVALID_INPUT);
    this.statusClass = undefined;
  }

  setErrorOnFormControl(errorKey: string): void {
    const errorObj: Record<string, boolean> = {
      ...this.field.formControl.errors,
    };
    errorObj[errorKey] = true;
    this.field.formControl.setErrors({
      ...errorObj,
    });
    this.field.formControl.updateValueAndValidity();
    this.deleteFetchedDataKeyInFormState();
  }

  useBaseUrlHandler(url: string): string {
    if (
      this.field.props['useBaseUrl'] &&
      !this.field.options?.formState?.useMock
    ) {
      return updateUrlWithApiGatewayBaseUrl(url, this.environment);
    }
    if (this.field.options?.formState?.useMock) {
      return updateUrlWithMockBaseUrl(url, this.environment);
    }
    return url;
  }

  deleteFetchedDataKeyInFormState(): void {
    this.formStateService.deleteFetchedDataKeyInFormState(
      <string>this.field.key
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
