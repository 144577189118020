<div class="row section-content" style="line-height: 28px">
  <div class="col">
    <p class="text-color" style="font-size: 18px">
      {{
        'Thank you for visiting Irembo e-gov portal (the "Portal"). As\
          is true for many other Web sites, this Portal has rules that\
          apply to your use and any services available through this\
          Portal. Those rules, referred to as "terms of use", are set\
          forth in this Terms of Use. By using this Portal, you are\
          agreeing to comply with and be bound by the following terms of\
          use. Please review them carefully. If you do not agree with\
          any of these terms of use, please do not use this Portal.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Who owns this portal and how it operates' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'This Portal functions as a real time electronic processing\
          system for services application process between Government\
          Authorities and End-Users and such transactional interface is\
          designed and maintained by the Irembo Ltd., a private company\
          under a concession agreement of 25 year with the Government of\
          Rwanda.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'It is under the sole responsibility of the Government and each\
          participating Government Authority to develop and maintain the\
          information web pages, backends and supply Irembo with any\
          initial and updated information required during the provision\
          of the services to the End-Users.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">{{ 'Registration' | translate }}</h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'You may be required to register on Irembo Portal account to\
          access some of the online services. However, it is not a\
          requirement when using our USSD *909#.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'User responsibility' | translate }}
    </h4>
    <p
      class="text-color"
      style="font-size: 18px"
      [innerHTML]="USER_RESPONSIBILITY_TEXT | translate"></p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Right to make changes' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px">
      {{
        'Irembo may revise this Privacy Policy from time to time and\
          when such a change is made; we will post a revised version on\
          this Website. Please note that changes are effective when they\
          are posted and it is your responsibility to read the Privacy\
          Policy from time to time in order that you are aware of any\
          such change. In our sole discretion, and if you are a\
          registered user, we may notify you via email associated with\
          your account or by SMS.' | translate
      }}
    </p>
    <p class="text-color" style="font-size: 18px">
      {{
        'By continuing to access or use the platform after those\
          changes become effective, you agree to be bound by the revised\
          Privacy Policy.' | translate
      }}
    </p>
    <h4 style="padding: 32px 0 16px">
      {{ 'Compliance to the regulations' | translate }}
    </h4>
    <p class="text-color" style="font-size: 18px; padding-bottom: 8rem">
      {{
        'Irembo regularly reviews compliance with its Privacy Policy.\
          It also adheres to several self-regulatory frameworks. It\
          works with the appropriate regulatory authorities to resolve\
          any complaints on personal data that it cannot resolve with\
          our users directly.' | translate
      }}
    </p>
  </div>
</div>
