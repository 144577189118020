import { Injectable } from '@angular/core';
import { AnalyticsBaseService } from './analytics-base.service';
import {
  AnalyticsEvent,
  AnalyticsEventModel,
} from '../../models/analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private providers: AnalyticsBaseService[] = [];

  registerProvider(provider: AnalyticsBaseService) {
    this.providers.push(provider);
  }

  async trackEvent(
    event: Pick<AnalyticsEvent, 'eventType'> & Partial<AnalyticsEvent>
  ) {
    const analyticsEvent = new AnalyticsEventModel(event);
    this.providers.forEach(provider => provider.trackEvent(analyticsEvent));
  }

  setUser(userId: string, userProperties?: Record<string, any>) {
    this.providers.forEach(provider =>
      provider.setUser(userId, userProperties)
    );
  }

  reset() {
    this.providers.forEach(provider => provider.reset());
  }
}
