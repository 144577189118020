<div class="profile-settings">
  <a
    class="back-button text-decoration-none d-flex flex-row align-items-center"
    [routerLink]="['/profile']">
    <i class="icon-chevron-left me-2"></i> {{ 'Back to profile' | translate }}
  </a>

  <h1>{{ 'Profile settings' | translate }}</h1>
  <div class="divider"></div>
  <div
    class="personal-info d-flex flex-column flex-lg-row justify-content-lg-between justify-content-md-between align-items-start col-12">
    <h2>{{ 'Personal info' | translate }}</h2>
    <div
      class="info-container d-flex flex-column flex-lg-row align-items-start col-12 col-lg-8 col-md-8"
      *ngIf="keycloakProfile && userProfile">
      <div class="d-flex flex-column col-lg-6 col-md-6 gap-4">
        <div class="info-item">
          <span class="label">{{ 'Status' | translate }}</span>
          <div class="status verified" *ngIf="userProfile.accountVerified">
            <i class="icon-check-verified-01"></i>{{ 'Verified' | translate }}
          </div>
          <div class="status pending" *ngIf="!userProfile.accountVerified">
            <i class="icon-info-circle"></i
            >{{ 'Pending verification' | translate }}
          </div>
        </div>
        <div class="info-item d-flex flex-row">
          <div class="d-flex flex-column">
            <span class="label">{{ 'National ID Number' | translate }}</span>
            <span class="nid">{{ userProfile.identificationNumber }}</span>
          </div>

          <div *ngIf="userProfile.identificationNumber" class="edit-container">
            <button
              class="btn edit-button"
              (click)="openUpdateNIDModal(updateNID)">
              <i class="icon-edit-05 me-1"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="info-item col-12 col-lg-6 col-md-6">
        <span class="label">{{ 'Username' | translate }}</span>
        <span class="username">{{ keycloakProfile.username }}</span>
      </div>
    </div>
  </div>
  <div class="divider d-none d-lg-block"></div>
  <div
    class="personal-info d-flex flex-column flex-lg-row align-items-start justify-content-lg-between justify-content-md-between col-12">
    <h2>{{ 'Notification settings' | translate }}</h2>
    <div
      class="notifications-settings d-flex flex-column align-items-start col-12 col-lg-8 col-md-8">
      <div
        class="info-items d-flex flex-column flex-lg-row justify-content-lg-between align-items-start col-12">
        <div
          class="info-item col-12 col-lg-6 col-md-6"
          *ngIf="userProfile.notificationPhoneNumber">
          <span class="label">{{ 'Phone Number' | translate }}</span>
          <span class="value">{{ userProfile.notificationPhoneNumber }}</span>
        </div>
        <div
          class="info-item col-12 col-lg-6 col-md-6"
          *ngIf="userProfile.notificationEmail">
          <span class="label">{{ 'Primary Email' | translate }}</span>
          <span class="value">{{ userProfile.notificationEmail }}</span>
        </div>
      </div>
      <div
        class="edit-action d-flex justify-content-end justify-content-lg-start align-items-center w-100">
        <button
          class="btn edit-button"
          (click)="openUpdateNotificationsModal(updateNotifications)">
          <i class="icon-edit-05 me-1"></i> {{ 'Edit' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #updateNotifications let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Update notification settings' | translate }}
    </h4>
    <i
      type="button"
      class="icon-x-close"
      aria-label="Close"
      (click)="modal.dismiss()"></i>
  </div>
  <div class="modal-body">
    <form
      class="d-flex flex-column gap-3 col-12"
      [formGroup]="updateNotificationsForm">
      <div class="col-12">
        <label for="phoneNumber"> {{ 'Phone number' | translate }}</label>
        <input
          type="tel"
          class="form-control form-control-lg"
          id="phoneNumber"
          formControlName="phoneNumber"
          [placeholder]="'Enter phone number' | translate" />
        <div
          id="phoneNumberHelpBlock"
          class="form-text"
          [ngClass]="{
            'text-danger':
              updateNotificationsForm.controls['phoneNumber'].invalid &&
              updateNotificationsForm.controls['phoneNumber'].touched
          }">
          {{ '10 digit Rwandan phone number required.' | translate }}
        </div>
      </div>
      <div class="col-12">
        <label for="email"> {{ 'Email' | translate }}</label>
        <input
          type="email"
          class="form-control form-control-lg"
          id="email"
          formControlName="email"
          [placeholder]="'Enter email address' | translate" />
        <p
          *ngIf="
            updateNotificationsForm.controls['email'].invalid &&
            updateNotificationsForm.controls['email'].touched
          "
          class="text-danger small mt-1">
          {{ 'Please provide a valid email to proceed' | translate }}
        </p>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex flex-column gap-lg-2 flex-lg-row-reverse">
    <button
      type="button"
      class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
      [disabled]="updateNotificationsForm.invalid || updating"
      (click)="updateNotificationsChanges()">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="updating">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Save changes' | translate }}
    </button>
    <button
      type="button"
      class="btn cancel-btn col-12 col-lg-5 flex-lg-fill"
      (click)="modal.dismiss()">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #updateNID let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ 'Update National ID' | translate }}
    </h4>
    <i
      type="button"
      class="icon-x-close"
      aria-label="Close"
      (click)="cancelVerifyUpdateNID()"></i>
  </div>
  <p class="modal-subtitle">
    {{ 'Enter your new National ID number below' | translate }}
  </p>
  <div class="modal-body">
    <form class="d-flex flex-column gap-3 col-12" [formGroup]="updateNIDForm">
      <div class="col-12">
        <label for="currentNID"> {{ 'Current National ID' | translate }}</label>
        <input
          type="number"
          class="form-control form-control-lg"
          id="currentNID"
          [value]="userProfile.identificationNumber"
          readonly />
      </div>
      <div class="col-12">
        <label for="newNID"> {{ 'New National ID' | translate }}</label>
        <input
          type="number"
          class="form-control form-control-lg"
          id="newNID"
          formControlName="identificationNumber"
          [placeholder]="'Enter your new National ID' | translate" />
        <p
          *ngIf="
            updateNIDForm.controls['identificationNumber'].invalid &&
            updateNIDForm.controls['identificationNumber'].touched
          "
          class="text-danger small mt-1">
          {{ 'Please provide a valid national ID to proceed' | translate }}
        </p>
      </div>
      <div class="col-12">
        <label for="phoneNumber"> {{ 'Phone number' | translate }}</label>
        <input
          type="tel"
          class="form-control form-control-lg"
          id="phoneNumber"
          formControlName="phoneNumber"
          [placeholder]="'Enter your phone number' | translate" />
        <p
          *ngIf="
            updateNIDForm.controls['phoneNumber'].invalid &&
            updateNIDForm.controls['phoneNumber'].touched
          "
          class="text-danger small mt-1">
          {{ 'Please provide a valid phone number' | translate }}
        </p>
        <p class="modal-subtitle py-2 px-0">
          {{
            'A verification code will be sent to your phone number to confirm your identity. This helps us ensure the security of your National ID update.'
              | translate
          }}
        </p>
      </div>
    </form>
  </div>
  <div class="modal-footer d-flex flex-column gap-lg-2 flex-lg-row-reverse">
    <button
      type="button"
      class="btn btn-primary fw-semibold col-12 col-lg-5 flex-lg-fill"
      [disabled]="updateNIDForm.invalid || updating"
      (click)="updateNIDChanges()">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="updating">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Update National ID' | translate }}
    </button>
    <button
      type="button"
      class="btn cancel-btn col-12 col-lg-5 flex-lg-fill"
      (click)="cancelVerifyUpdateNID()">
      {{ 'Cancel' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #tokenModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title w-90 mt-2" id="modal-basic-title">
      {{ 'Verify Your Identity' | translate }}
    </h4>

    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      (click)="cancelVerifyUpdateNID()"></button>
  </div>
  <div
    class="modal-body responsive-otp-input pt-0 pb-4"
    [ngClass]="{ 'invalid-token': invalidToken }">
    <div class="w-100 mb-2">
      <p class="form-text">
        {{ 'Enter the 5-digit code sent to your phone number' | translate }} ***
        *** {{ phoneLastDigits }}
      </p>
    </div>
    <ng-otp-input
      #ngOtpInputRef
      (onInputChange)="onOtpChange($event, ngOtpInputRef)"
      [config]="{
        length: 5,
        placeholder: '0',
        inputClass: 'formControl',
        containerClass: 'custom-token-input'
      }"></ng-otp-input>
    <p *ngIf="invalidToken" class="text-danger mt-2">
      {{
        'The code you entered is not valid. Please request a new code to continue'
          | translate
      }}.
    </p>
    <div class="w-100 d-flex justify-content-start">
      <p class="mt-4">
        {{ 'Didn’t receive the email' | translate }}?
        <span class="resendLink" (click)="resendUpdateNIDOTP()">{{
          'Click to resend' | translate
        }}</span>
      </p>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn w-100 btn-outline-dark cancel-btn"
      (click)="cancelVerifyUpdateNID()">
      {{ 'Cancel' | translate }}
    </button>
    <button
      type="button"
      [disabled]="token.length < 5"
      (click)="confirmUpdateNID(ngOtpInputRef)"
      class="btn w-100 btn-primary">
      <div
        class="spinner-border spinner-border-sm"
        role="status"
        *ngIf="isVerifyingUpdateNIDToken">
        <span class="visually-hidden">{{ 'Loading' | translate }}...</span>
      </div>
      {{ 'Confirm' | translate }}
    </button>
  </div>
</ng-template>
