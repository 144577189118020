import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CERTIFICATESTATUS } from '../../models/certificate-status.enum';
import { NgOtpInputComponent } from 'ng-otp-input';
import { PreviewCertificateComponent } from '../preview-certificate/preview-certificate.component';
import { ApplicationsService } from '../../services/applications.service';
import { Subscription } from 'rxjs';
import {
  AnalyticsEventType,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { MixpanelService } from '../../services/analytics/mixpanel.service';

@Component({
  selector: 'irembogov-verify-certificate',
  templateUrl: './verify-certificate.component.html',
  styleUrls: ['./verify-certificate.component.scss'],
})
export class VerifyCertificateComponent implements OnInit {
  verifyForm: FormGroup;
  invalidToken = false;
  applicationStatus: CERTIFICATESTATUS | undefined;
  otp = '';
  isPreviewingCertificate = false;
  verifying = false;
  private subscriptions = new Subscription();

  constructor(
    private modalService: NgbModal,
    private applicationService: ApplicationsService,
    private toastService: ToastService,
    private analyticsService: MixpanelService
  ) {
    this.verifyForm = new FormGroup({
      applicationNo: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(19),
        Validators.maxLength(19),
      ]),
    });
  }

  ngOnInit(): void {
    this.applicationStatus = CERTIFICATESTATUS.VALID;
    this.analyticsService.trackEvent({
      eventType: AnalyticsEventType.VERIFY_CERTIFICATE,
    });
  }

  openModel(
    content: unknown,
    windowClass: string,
    size?: string,
    backdrop?: string
  ) {
    let options: Record<string, unknown> = {
      ariaLabelledBy: 'verify-certificate',
      windowClass,
      centered: true,
      size,
    };
    if (backdrop) {
      options = { ...options, backdrop };
    }
    return this.modalService.open(content, options);
  }

  openVerifyModal(content: TemplateRef<unknown>) {
    this.verifying = true;
    this.analyticsService.trackEvent({
      eventType: AnalyticsEventType.VERIFY_CERTIFICATE_INITIATED,
    });
    this.applicationService
      .checkApplicationCertificateValidity(
        this.verifyForm.get('applicationNo')?.value
      )
      .subscribe({
        next: res => {
          this.verifying = false;
          this.applicationStatus = res.data.validityStatus;
          this.openModel(content, 'verify-certificate-modal');

          if (this.applicationStatus === CERTIFICATESTATUS.VALID) {
            this.analyticsService.trackEvent({
              eventType: AnalyticsEventType.VERIFY_CERTIFICATE_INITIATED,
            });
          } else {
            this.analyticsService.trackEvent({
              eventType: AnalyticsEventType.VERIFY_CERTIFICATE_ERROR,
              errorCode: this.applicationStatus,
              errorMessage: 'The certificate is invalid',
            });
          }
        },
        error: err => {
          this.verifying = false;
          this.verifying = false;
          this.toastService.show({
            body: 'Error verifying application number',
            type: 'error',
          });

          this.analyticsService.trackEvent({
            eventType: AnalyticsEventType.VERIFY_CERTIFICATE_ERROR,
            errorCode: err?.error?.responseCode || 'VERIFY_CERTIFICATE_ERROR',
            errorMessage:
              err?.error?.responseMessage ||
              'Error verifying application number',
          });
        },
      });
  }

  openTokenModal(content: TemplateRef<unknown>) {
    this.invalidToken = false;
    this.otp = '';
    this.modalService.dismissAll();
    this.openModel(content, 'token-input-modal');
  }

  getApplicationStatusIcon(status: string): string {
    const icons: Record<string, string> = {
      INVALID: 'modal_icon_close.svg',
      VALID: 'modal_icon_success.svg',
      EXPIRED: 'modal_icon_warning.svg',
    };
    return icons[status];
  }

  onOtpChange(event: string, ngOtpInputRef: NgOtpInputComponent) {
    this.otp = event;
    if (ngOtpInputRef) {
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          if (!element.value) {
            element.reset();
          }
        }
      }
    }
  }

  confirmCertificate(ngOtpInputRef: NgOtpInputComponent) {
    this.isPreviewingCertificate = true;
    this.subscriptions.add(
      this.applicationService
        .verifyApplicationToken(
          this.verifyForm.get('applicationNo')?.value,
          this.otp
        )
        .subscribe({
          next: res => {
            this.isPreviewingCertificate = false;
            const base64Data = `data:${res.data.contentType};base64,${res.data.file}`;
            this.modalService.dismissAll();
            const modalRef = this.openModel(
              PreviewCertificateComponent,
              'certificate-preview',
              'xl',
              'static'
            );
            modalRef.componentInstance.base64Data = base64Data;
          },
          error: () => {
            this.markControlsAsInValid(ngOtpInputRef);
            this.isPreviewingCertificate = false;
          },
        })
    );
  }

  private markControlsAsInValid(ngOtpInputRef: NgOtpInputComponent) {
    if (ngOtpInputRef) {
      this.invalidToken = true;
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          element.setErrors({ invalidToken: true });
        }
      }
    }
  }
}
