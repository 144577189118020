import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@irembo-andela/irembogov3-common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { Subscription, finalize } from 'rxjs';
import {
  IUserProfile,
  IdentificationType,
} from '../../../../models/user-profile.model';
import { UserProfileService } from '../../../../services/user-profile.service';
import { environment } from '../../../../../environments/environment';
import { NgOtpInputComponent } from 'ng-otp-input';
import { ERROR_MAP } from '../../../../data/errorMap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'irembogov-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss'],
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {
  keycloakProfile: KeycloakProfile = {};
  isLoggedIn = false;
  updateNotificationsForm!: FormGroup;
  updating = false;
  doneLoadingProfile = false;
  emailVerificationPattern = /^[\w\-\\.]+@([\w-]+\.)+[\w-]{2,4}$/;
  subscriptions = new Subscription();
  userProfile: IUserProfile = {
    userId: '',
    identificationType: IdentificationType.CITIZEN_APPLICATION_NUMBER,
    identificationNumber: '',
    accountVerified: false,
    notificationPhoneNumber: '',
    notificationEmail: '',
    notificationType: {
      PHONE_NUMBER: true,
      EMAIL: false,
    },
  };
  updateNIDForm!: FormGroup;
  invalidToken = false;
  phoneLastDigits = '';
  token = '';
  isVerifyingUpdateNIDToken = false;

  @ViewChild('tokenModal')
  verifyTokenModalContent!: TemplateRef<unknown>;

  constructor(
    private modalService: NgbModal,
    private toastService: ToastService,
    private userProfileService: UserProfileService,
    private keycloak: KeycloakService,
    private translateService: TranslateService
  ) {}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.keycloak.isLoggedIn();
    if (this.isLoggedIn)
      this.keycloakProfile = await this.keycloak.loadUserProfile();
    this.updateNotificationsForm = new FormGroup({
      phoneNumber: new FormControl<string>('', [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(environment.PHONE_NUMBER_REGEX),
      ]),
      email: new FormControl<string>('', [
        Validators.pattern(this.emailVerificationPattern),
      ]),
    });

    this.updateNIDForm = new FormGroup({
      identificationNumber: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(16),
        Validators.maxLength(16),
        Validators.pattern('^[0-9]+$'),
      ]),
      phoneNumber: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern(environment.PHONE_NUMBER_REGEX),
      ]),
    });

    this.getUserProfile();
  }

  openModel(
    content: unknown,
    windowClass: string,
    size?: string,
    backdrop?: string
  ) {
    let options: Record<string, unknown> = {
      ariaLabelledBy: 'update-notifications',
      windowClass,
      centered: true,
      size,
    };
    if (backdrop) {
      options = { ...options, backdrop };
    }
    return this.modalService.open(content, options);
  }

  openUpdateNotificationsModal(content: TemplateRef<unknown>) {
    this.openModel(content, 'update-notifications-modal');
  }

  openUpdateNIDModal(content: TemplateRef<unknown>) {
    this.openModel(content, 'update-nid-modal');
  }

  openVerifyNIDModal(content: TemplateRef<unknown>) {
    this.openModel(content, 'verify-nid-modal');
  }

  updateNotificationsChanges() {
    const phoneNumberControl = this.updateNotificationsForm.get('phoneNumber');
    const emailControl = this.updateNotificationsForm.get('email');

    if (
      this.updateNotificationsForm.invalid ||
      (phoneNumberControl?.value === '' && emailControl?.value === '')
    ) {
      this.updating = false;
      return this.toastService.show({
        type: 'error',
        title: 'Error saving notification settings',
        body: 'Provide at least one contact detail (email or phone number)',
        delay: 5000,
      });
    }

    this.updating = true;

    const params = {
      notificationPhoneNumber:
        phoneNumberControl?.value !== '' ? phoneNumberControl?.value : null,
      notificationEmail:
        emailControl?.value !== '' ? emailControl?.value : null,
    };

    this.subscriptions.add(
      this.userProfileService.addUpdateNotificationChannel(params).subscribe({
        next: res => {
          this.toastService.show({
            body: "You'll now receive alerts based on your preferences.",
            title: 'Notification settings updated!',
            type: 'success',
            delay: 5000,
          });
          this.getUserProfile();
          this.userProfileService.updateUserProfile(res.data);
          this.updating = false;
          this.modalService.dismissAll();
        },
        error: () => {
          this.updating = false;
          this.toastService.show({
            body: 'Saving preferences failed, please try again!',
            title: 'Error saving notification settings',
            type: 'error',
            delay: 5000,
          });
        },
      })
    );
  }

  updateNIDChanges() {
    const identificationNumberControl = this.updateNIDForm.get(
      'identificationNumber'
    );
    const phoneNumberControl = this.updateNIDForm.get('phoneNumber');

    this.updating = true;

    const params = {
      identificationNumber: identificationNumberControl?.value,
      identificationType: IdentificationType.NID,
      phoneNumber: phoneNumberControl?.value,
    };

    this.subscriptions.add(
      this.userProfileService.addUpdateNIDChannel(params).subscribe({
        next: res => {
          this.updating = false;
          this.modalService.dismissAll();
          this.phoneLastDigits = phoneNumberControl?.value.slice(-4);
          this.openVerifyNIDModal(this.verifyTokenModalContent);
        },
        error: error => {
          const responseCode = <string>error.error.responseCode;
          const errorMessage =
            ERROR_MAP[responseCode] ??
            'Updating National ID failed, please try again!';

          this.updating = false;
          this.toastService.show({
            body: this.translateService.instant(errorMessage),
            title: this.translateService.instant('Error updating National ID'),
            type: 'error',
            delay: 5000,
          });
        },
      })
    );
  }

  getUserProfile() {
    this.subscriptions.add(
      this.userProfileService
        .getUserProfile()
        .pipe(finalize(() => (this.doneLoadingProfile = true)))
        .subscribe({
          next: res => {
            this.userProfile = res;
            this.fillFormData(res);
          },
          error: () => {
            this.toastService.show({
              body: 'Error loading notification preferences',
              title: 'Error loading notification preferences',
              type: 'error',
            });
          },
        })
    );
  }

  fillFormData(data: IUserProfile) {
    this.updateNotificationsForm.controls['email'].setValue(
      data.notificationEmail
    );
    this.updateNotificationsForm.controls['phoneNumber'].setValue(
      data.notificationPhoneNumber
    );
  }

  onOtpChange(event: string, ngOtpInputRef: NgOtpInputComponent) {
    this.token = event;

    if (ngOtpInputRef) {
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          if (!element.value) {
            element.reset();
          }
          element.setErrors(null);
        }
      }
    }
  }

  resendUpdateNIDOTP() {
    this.updateNIDChanges();
  }

  cancelVerifyUpdateNID() {
    this.updateNIDForm.reset();
    this.invalidToken = false;

    this.modalService.dismissAll();
  }

  confirmUpdateNID(ngOtpInputRef: NgOtpInputComponent) {
    if (!this.token) {
      return;
    }
    if (!this.updateNIDForm.valid) {
      return;
    }
    this.isVerifyingUpdateNIDToken = true;

    const params = {
      identificationNumber: this.updateNIDForm.get('identificationNumber')
        ?.value,
      identificationType: IdentificationType.NID,
      phoneNumber: this.updateNIDForm.get('phoneNumber')?.value,
      otp: this.token,
    };

    this.subscriptions.add(
      this.userProfileService.addUpdateNIDChannel(params).subscribe({
        next: res => {
          this.toastService.show({
            body: 'Your National ID number has been successfully updated.',
            title: 'National ID Updated Successfully',
            type: 'success',
            delay: 5000,
          });
          this.updateNIDForm.reset();
          this.getUserProfile();
          this.userProfileService.updateUserProfile(res.data);
          this.updating = false;
          this.modalService.dismissAll();
        },
        error: error => {
          this.isVerifyingUpdateNIDToken = false;

          if (error.error?.responseMessage.includes('OTP_')) {
            this.markControlsAsInValid(ngOtpInputRef);
          } else {
            const responseCode = <string>error.error.responseCode;
            const errorMessage =
              ERROR_MAP[responseCode] ??
              'Updating National ID failed, please try again!';

            this.toastService.show({
              body: this.translateService.instant(errorMessage),
              title: this.translateService.instant(
                'Error updating National ID'
              ),
              type: 'error',
              delay: 5000,
            });
          }
        },
      })
    );
  }

  private markControlsAsInValid(ngOtpInputRef: NgOtpInputComponent) {
    if (ngOtpInputRef) {
      this.invalidToken = true;
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          element.setErrors({ invalidToken: true });
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
