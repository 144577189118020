export enum AnalyticsUserRole {
  // Admin roles
  IREMBOGOV_ADMIN = 'IREMBOGOV_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  AGENCY_ADMIN = 'AGENCY_ADMIN',
  ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN',
  OFFICER_ADMIN = 'OFFICER_ADMIN',

  // Manager roles
  SERVICE_DISTRIBUTION_MANAGER = 'SERVICE_DISTRIBUTION_MANAGER',
  PARTNERSHIP_MANAGER = 'PARTNERSHIP_MANAGER',
  INTEGRATION_MANAGER = 'INTEGRATION_MANAGER',
  OFFICER_MANAGER = 'OFFICER_MANAGER',
  SERVICE_MANAGER = 'SERVICE_MANAGER',

  // Officer/Agent roles
  OFFICER = 'OFFICER',
  AGENT = 'AGENT',
  INTEGRATION_OPS = 'INTEGRATION_OPS',

  // Organization roles
  ORGANIZATION_EXECUTIVE = 'ORGANIZATION_EXECUTIVE',
  ORGANIZATION_SUPPORT = 'ORGANIZATION_SUPPORT',

  // Other roles
  CITIZEN = 'CITIZEN',
  QA_REVIEWER = 'QA_REVIEWER',
}
