import { Directive, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AnalyticsService } from './analytics.service';
import { AnalyticsEvent } from '../../models/analytics/analytics-event.model';

@Directive({
  selector: '[irembogovTrackEvent]',
})
export class TrackEventDirective implements OnInit, OnDestroy {
  @Input('irembogovTrackEvent') event!: Pick<AnalyticsEvent, 'eventType'> &
    Partial<AnalyticsEvent>;
  @Input() trackTrigger: 'click' | 'focus' | 'blur' | 'change' = 'click';

  private destroy$ = new Subject<void>();

  constructor(
    private el: ElementRef,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    if (!this.event) {
      console.warn('No event configuration provided to TrackEventDirective');
      return;
    }

    fromEvent(this.el.nativeElement, this.trackTrigger)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.analyticsService.trackEvent(this.event);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
