import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormStateService } from '../../../services/formly/form-state.service';
import { BehaviorSubject, distinctUntilChanged, Subscription } from 'rxjs';
import { FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/bootstrap/form-field';
import { alphaNanoIdUtil } from '../../../../utils/utils/alpha-nano-id.util';

export interface GlobalErrorFieldConfig extends FormlyFieldProps {
  errorKey?: string | number | (string | number)[];
  layout?: string;
  nonConfigurable?: boolean;
  [key: string]: any;
}

@Component({
  selector: 'irembogov-formly-global-error',
  templateUrl: './irembogov-formly-global-error.component.html',
  styleUrls: ['./irembogov-formly-global-error.component.css'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IrembogovFormlyGlobalErrorComponent
  extends FieldType<FieldTypeConfig<GlobalErrorFieldConfig>>
  implements OnInit, OnDestroy
{
  private readonly subscription = new Subscription();
  error$ = new BehaviorSubject<any>(null);

  constructor(private readonly formStateService: FormStateService) {
    super();
    this.defaultProps();
  }

  ngOnInit() {
    this.subscription.add(
      this.formStateService
        .getFormlyFormStateSubject()
        .pipe(
          distinctUntilChanged((prev, curr) => {
            // Only update if the error matches our errorKey
            const prevError = prev?.globalError;
            const currError = curr?.globalError;

            if (this.field.props?.['errorKey']) {
              // If errorKey is specified, only show errors for that key
              return (
                prevError?.field === this.field.props?.['errorKey'] &&
                currError?.field === this.field.props?.['errorKey'] &&
                JSON.stringify(prevError) === JSON.stringify(currError)
              );
            }

            // If no errorKey specified, show all errors
            return JSON.stringify(prevError) === JSON.stringify(currError);
          })
        )
        .subscribe(state => {
          const error = state?.globalError;
          if (this.field.props?.['errorKey']) {
            // Only show error if it matches our errorKey
            this.error$.next(
              error?.field === this.field.props?.['errorKey'] ? error : null
            );
          } else {
            // Show any error
            this.error$.next(error || null);
          }
        })
    );
  }

  clearError() {
    this.formStateService.clearGlobalError();
  }

  defaultProps() {
    if (this.field) {
      // Ensure props object exists
      this.field.props = this.field.props || {};

      // Set mandatory properties
      this.field.props = {
        ...this.field.props,
        nonConfigurable: true,
        hideHeader: true,
        hideLabel: true,
        // Ensure errorKey exists
        errorKey:
          this.field.props?.['errorKey'] ||
          this.field.key ||
          `InputKey_${alphaNanoIdUtil(5)}`,
      };
    }
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
