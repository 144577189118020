import { AnalyticsUserType } from '../../models/analytics/enums/analytics-user-type.enum';
import { AnalyticsUserRole } from '../../models/analytics/enums/analytics-user-role.enum';

export class AnalyticsRoleUtils {
  static getUserType(roles: string[]): AnalyticsUserType {
    if (roles.some(role => role.includes('ADMIN'))) {
      return AnalyticsUserType.ADMIN;
    }

    if (roles.some(role => role.includes('MANAGER'))) {
      return AnalyticsUserType.MANAGER;
    }

    if (roles.some(role => role.includes('ORGANIZATION'))) {
      return AnalyticsUserType.ORGANIZATION;
    }

    if (roles.includes('ROLE_OFFICER')) {
      return AnalyticsUserType.OFFICER;
    }

    if (roles.includes('ROLE_AGENT')) {
      return AnalyticsUserType.AGENT;
    }

    if (roles.includes('ROLE_CITIZEN')) {
      return AnalyticsUserType.CITIZEN;
    }

    return AnalyticsUserType.LAMBDA;
  }

  static getUserRole(roles: string[]): AnalyticsUserRole {
    const cleanRoles = roles.map(role => role.replace('ROLE_', ''));

    for (const role of cleanRoles) {
      const analyticsRole =
        AnalyticsUserRole[role as keyof typeof AnalyticsUserRole];
      if (analyticsRole) {
        return analyticsRole;
      }
    }

    return AnalyticsUserRole.CITIZEN;
  }
}
