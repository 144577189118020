export const ERROR_MAP: Record<string, string> = {
  EMAIL_ALREADY_EXIST: 'This account already exists. Please login',
  PHONE_ALREADY_EXIST: 'This account already exists. Please login',
  INVALID_EMAIL_ADDRESS:
    'The email provided is invalid, please provide a correct email to proceed',
  INVALID_PHONE_NUMBER: 'Phone Number must be 10 digits',
  EMAIL_ALREADY_EXIST_DEACTIVATED: 'EMAIL_ALREADY_EXIST_DEACTIVATED',
  PHONE_NUMBER_ALREADY_EXIST_DEACTIVATED:
    'PHONE_NUMBER_ALREADY_EXIST_DEACTIVATED',
  OTP_EXPIRED: 'The provided code is expired',
  ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED:
    'ACCOUNT_USER_ALREADY_EXIST_NOT_ACTIVATED',
  ACCOUNT_NOT_FOUND: 'Account not found',
  BUSINESS_USER_ALREADY_EXISTS: 'This business user already exists.',
  CITIZEN_USER_NOT_FOUND: 'This user does not exists.',
  ACCOUNT_NOT_VERIFIED: 'Account not verified',
  IDENTITY_NUMBER_SAME_AS_CURRENT:
    'Your new National ID is the same as the current one',
  IDENTITY_NUMBER_NOT_FOUND: 'National ID not found',
  IDENTITY_NUMBER_PATTERN_MISMATCH:
    'Your new National ID is not related to the current one',
  INVALID_IDENTITY_NUMBER_FORMAT: 'National ID must be 16 digits',
  NID_AND_PHONE_NUMBER_NOT_LINKED:
    'The phone number and national id you provided do not match in the national registry. Please try a different number.',
};
