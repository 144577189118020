import { AnalyticsStandardProperties } from './analytics-properties.model';
import { AnalyticsAccountType } from './enums/analytics-account-type.enum';
import { AnalyticsSearchType } from './enums/analytics-search-type.enum';
import { AnalyticsUserRole } from './enums/analytics-user-role.enum';
import { AnalyticsUserType } from './enums/analytics-user-type.enum';

export interface AnalyticsEvent extends AnalyticsStandardProperties {
  eventType: string;
  metadata?: Record<string, any>;
}

export class AnalyticsEventModel {
  eventType: string;
  metadata?: Record<string, any>;

  // Standard properties
  service_category_name?: string;
  service_code?: string;
  service_name?: string;
  institution_name?: string;
  user_type?: AnalyticsUserType;
  user_role?: AnalyticsUserRole;
  user_id?: string;
  language?: string;
  account_type?: AnalyticsAccountType;
  search_type?: AnalyticsSearchType;
  timestamp: number;
  errorMessage?: string;
  errorCode?: string | number;

  constructor(
    data: Pick<AnalyticsEvent, 'eventType'> & Partial<AnalyticsEvent>
  ) {
    this.eventType = data.eventType;
    this.metadata = data.metadata || {};
    this.timestamp = Date.now();

    // Map standard properties
    this.service_category_name = data.service_category_name;
    this.service_code = data.service_code;
    this.service_name = data.service_name;
    this.institution_name = data.institution_name;
    this.user_type = data.user_type;
    this.user_role = data.user_role;
    this.user_id = data.user_id;
    this.language = data.language;
    this.account_type = data.account_type;
    this.search_type = data.search_type;
    this.errorMessage = data.errorMessage;
    this.errorCode = data.errorCode;
  }
}
