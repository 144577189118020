import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AnalyticsEventType,
  IApplication,
  IApplicationFormData,
  ToastService,
  applicationFormBuildPreviewData,
  applicationStatusEnumToColorUtil,
  applicationStatusEnumToLabelUtil,
  applicationStatusValueOf,
  AnalyticsHooks,
  AnalyticsSearchType,
} from '@irembo-andela/irembogov3-common';
import {
  Observable,
  Subscription,
  firstValueFrom,
  map,
  take,
  timer,
} from 'rxjs';
import { ApplicationsService } from '../../services/applications.service';
import { IResponse } from '../../models/response.model';
import { environment } from '../../../environments/environment';
import { NgOtpInputComponent } from 'ng-otp-input';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IApplicationCertificate,
  IRequestAccessResponse,
} from '../../models/track-application.model';
import { ILocale } from '../../models/locale/locale.model';
import { LocaleService } from '../../services/locale.service';
import { MixpanelService } from '../../services/analytics/mixpanel.service';

@Component({
  selector: 'irembogov-track-application',
  templateUrl: './track-application.component.html',
  styleUrls: ['./track-application.component.scss'],
})
export class TrackApplicationComponent implements OnInit, OnDestroy {
  isApplicationLoading = false;
  foundApplication!: IApplication | undefined;
  applicationNotFound = false;
  isGettingReceiptLoading = false;
  captureSearchOption!: string | undefined;
  findApplicationForm!: FormGroup;
  isDownloadingCertificate = false;
  private subscriptions = new Subscription();
  @ViewChild('applicationResults') applicationResults: ElementRef | undefined;
  applicationSummary: IApplicationFormData[] | undefined;
  applicationBaseUrl = environment.apiGatewayBaseUrl;
  applicationPath = '/application/v1/file-manager/application/';
  viewMoreDetails = false;
  invalidToken = false;
  otp = '';
  encodedOtp = '';
  isConfirming = false;
  requestingAccess = false;
  applicationDetails: Record<string, unknown> | undefined;
  requestAccessResponse: IRequestAccessResponse | undefined;

  countdown$: Observable<number> | undefined;
  countdownTime = '00:00';
  minutes = 5;
  countdownSubscription: Subscription | undefined;

  resendingToken = false;
  tokenResent = false;
  downloadingCert = false;
  downloadingReciept = false;
  selectedPanel: number | undefined;
  supportedLocales: ILocale[] = [];
  selectedCertLocale: string | undefined;
  hideSearchOnMobile: boolean | undefined;
  locale = '';

  constructor(
    private applicationService: ApplicationsService,
    private ts: ToastService,
    private modalService: NgbModal,
    private localeService: LocaleService,
    private analyticsHooks: AnalyticsHooks,
    private analyticsService: MixpanelService
  ) {}

  async ngOnInit(): Promise<void> {
    this.findApplicationForm = new FormGroup({
      searchBy: new FormControl<string>('APP_NO', [Validators.required]),
      searchKeyword: new FormControl<string>('', [
        Validators.required,
        Validators.minLength(19),
        Validators.maxLength(19),
      ]),
    });

    this.findApplicationForm.get('searchBy')?.valueChanges.subscribe(value => {
      if (value === 'APP_NO') {
        this.searchKeyword?.setValidators([
          Validators.required,
          Validators.minLength(19),
          Validators.maxLength(19),
        ]);
      } else {
        this.searchKeyword?.setValidators([
          Validators.required,
          Validators.minLength(12),
          Validators.maxLength(12),
        ]);
      }

      this.searchKeyword?.updateValueAndValidity();
    });
    this.locale = localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE;

    this.analyticsService.trackEvent({
      eventType: AnalyticsEventType.TRACK_APPLICATION,
    });
  }

  get searchKeyword() {
    return this.findApplicationForm.get('searchKeyword');
  }

  clearSearch() {
    this.searchKeyword?.setValue('');
    this.findApplicationForm.reset();
  }

  findApplication() {
    if (this.findApplicationForm.valid) {
      const searchKey = this.findApplicationForm.get('searchBy')?.value;
      const searchParams = this.searchKeyword?.value;

      this.resetTrackApplication();

      this.analyticsHooks.withTracking(
        {
          eventType: AnalyticsEventType.SEARCH_APPLICATION,
          search_type:
            searchKey === 'APP_NO'
              ? AnalyticsSearchType.APPLICATION_NUMBER
              : AnalyticsSearchType.BILL_ID,
        },
        async () => {
          this.isApplicationLoading = true;

          try {
            // Convert observable to promise to properly handle errors
            if (searchKey === 'APP_NO') {
              const res = await firstValueFrom(
                this.applicationService.getTrackApplicatonByApplicationId(
                  searchParams
                )
              );
              this.applicationFound(res);
              this.hideSearchOnMobile = true;
            } else if (searchKey === 'BILL_ID') {
              const res = await firstValueFrom(
                this.applicationService.getTrackApplicatonByBillId(searchParams)
              );
              this.applicationFound(res);
              this.hideSearchOnMobile = true;
            }
          } catch (error) {
            this.isApplicationLoading = false;
            this.applicationNotFound = true;
            throw error; // Re-throw so withTracking can catch it
          }
        }
      );
    }
  }

  changeApplicationStatusToLabel(applicationStatus: string): string {
    return applicationStatusEnumToLabelUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  getApplicationStatusToColor(applicationStatus: string): string {
    return applicationStatusEnumToColorUtil(
      applicationStatusValueOf(applicationStatus)
    );
  }

  applicationFound(res: IResponse<IApplication>) {
    if (res.data === null) {
      this.isApplicationLoading = false;
      this.applicationNotFound = true;
    } else {
      this.isApplicationLoading = false;
      this.applicationNotFound = false;
      this.foundApplication = res.data;
      setTimeout(() => {
        this.scrollToSection(this.applicationResults);
      }, 0);
    }
  }

  scrollToSection(section: ElementRef | undefined) {
    if (section) {
      section.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }

  openModel(
    content: unknown,
    windowClass: string,
    size?: string,
    backdrop?: string
  ) {
    let options: Record<string, unknown> = {
      ariaLabelledBy: 'confirm-otp',
      windowClass,
      centered: true,
      size,
    };
    if (backdrop) {
      options = { ...options, backdrop };
    }
    return this.modalService.open(content, options);
  }

  handleViewMoreDetails(content: TemplateRef<unknown>) {
    if (this.foundApplication) {
      this.requestingAccess = true;
      this.subscriptions.add(
        this.applicationService
          .requestAccessByApplicationNumber(
            this.foundApplication.applicationNumber,
            this.locale
          )
          .subscribe({
            next: res => {
              if (
                res.responseCode === 'APPLICATION_OTP_NOT_REQUIRED' &&
                this.foundApplication
              ) {
                this.getDetails(this.foundApplication);
              } else {
                this.openOTPModal(content, res.data);
              }
            },
            error: () => {
              this.requestingAccess = false;
              this.showErrorToastMessage();
            },
          })
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByFn(index: number, item: IApplicationFormData) {
    return index;
  }

  downloadReciept() {
    if (this.foundApplication) {
      this.downloadingReciept = true;
      const downlaodName = `${this.foundApplication.applicationNumber}_payment_receipt`;
      this.subscriptions.add(
        this.applicationService
          .getPaymentReceiptByOTP(
            this.foundApplication.applicationNumber,
            this.otp
          )
          .subscribe({
            next: res => {
              this.downloadingReciept = false;
              this.handleFiledownload(
                res.data.file,
                res.data.contentType,
                downlaodName
              );
            },
            error: () => {
              this.downloadingReciept = false;
              this.ts.show({
                body: 'Error has occurred',
                type: 'error',
                delay: 5000,
              });
            },
          })
      );
    }
  }

  opendownloadCertificateModal(content: TemplateRef<unknown>) {
    this.modalService.dismissAll();
    this.openModel(content, 'download-cert-modal', undefined, 'static');
  }

  downloadCertificate(locale?: string) {
    if (this.foundApplication && locale) {
      this.downloadingCert = true;
      const downloadName = `${this.foundApplication.serviceName} - ${this.foundApplication.applicationNumber}`;
      this.subscriptions.add(
        this.applicationService
          .getCertificateByOTP(
            this.foundApplication.applicationNumber,
            this.otp,
            locale
          )
          .subscribe({
            next: res => {
              this.downloadingCert = false;
              this.handleFiledownload(
                res.data.file,
                res.data.contentType,
                downloadName
              );
              this.modalService.dismissAll();
              this.selectedCertLocale = undefined;
            },
            error: () => {
              this.downloadingCert = false;
              this.showErrorToastMessage();
            },
          })
      );
    }
  }

  onOtpChange(event: string, ngOtpInputRef: NgOtpInputComponent) {
    this.otp = event;
    this.encodedOtp = btoa(this.otp);
    if (ngOtpInputRef) {
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          if (!element.value) {
            element.reset();
          }
        }
      }
    }
  }

  confirm(ngOtpInputRef: NgOtpInputComponent) {
    if (this.foundApplication && this.otp.length == 5) {
      this.isConfirming = true;
      this.getDetailsByOTP(this.foundApplication, ngOtpInputRef);
    }
  }

  private markControlsAsInValid(ngOtpInputRef: NgOtpInputComponent) {
    if (ngOtpInputRef) {
      this.invalidToken = true;
      const controls = ngOtpInputRef.otpForm.controls;
      for (const key in controls) {
        if (Object.prototype.hasOwnProperty.call(controls, key)) {
          const element = controls[key];
          element.setErrors({ invalidToken: true });
        }
      }
    }
  }

  startCountdown() {
    const totalSeconds = this.minutes * 60;
    this.countdown$ = timer(0, 1000).pipe(
      take(totalSeconds + 1),
      map(elapsed => totalSeconds - elapsed)
    );

    this.countdownSubscription = this.countdown$.subscribe(seconds => {
      this.countdownTime = this.formatTime(seconds);
    });
  }

  formatTime(totalSeconds: number): string {
    const minutes: number = Math.floor(totalSeconds / 60);
    const seconds: number = totalSeconds % 60;
    return `${this.pad(minutes)}:${this.pad(seconds)}`;
  }

  pad(value: number): string {
    return value < 10 ? `0${value}` : value.toString();
  }

  restartCountdown() {
    this.countdownSubscription?.unsubscribe();
    this.startCountdown();
  }

  resendOTP() {
    if (this.foundApplication) {
      this.resendingToken = true;
      this.subscriptions.add(
        this.applicationService
          .requestAccessByApplicationNumber(
            this.foundApplication.applicationNumber,
            this.locale
          )
          .subscribe({
            next: () => {
              this.resendingToken = false;
              this.restartCountdown();
            },
            error: () => {
              this.resendingToken = false;
              this.ts.show({
                body: 'Error resending OTP',
                type: 'error',
                delay: 5000,
              });
            },
          })
      );
    }
  }

  resetTrackApplication() {
    this.foundApplication = undefined;
    this.applicationNotFound = false;
    this.viewMoreDetails = false;
    this.invalidToken = false;
    this.otp = '';
    this.isConfirming = false;
    this.requestingAccess = false;
    this.requestAccessResponse = undefined;
    this.applicationDetails = undefined;
    this.applicationSummary = undefined;
  }

  handleFiledownload(
    base64Data: string,
    contentType: string,
    fileName: string
  ) {
    const blob = this.base64ToBlob(base64Data, contentType);
    this.downloadBlob(fileName, blob);
  }

  private downloadBlob(fileName: string, blob: Blob): void {
    const anchor = window.document.createElement('a');
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(anchor.href);
  }

  private base64ToBlob(str: string, type: string) {
    const content = atob(str);
    const buffer = new ArrayBuffer(content.length);
    const view = new Uint8Array(buffer);
    for (let n = 0; n < content.length; n++) {
      view[n] = content.charCodeAt(n);
    }
    const blob = new Blob([buffer], { type: type });
    return blob;
  }

  expandPanel(index: number) {
    this.selectedPanel = index;
  }

  openOTPModal(content: TemplateRef<unknown>, data: IRequestAccessResponse) {
    this.requestingAccess = false;
    this.invalidToken = false;
    this.otp = '';
    this.modalService.dismissAll();
    this.openModel(
      content,
      'token-input-modal',
      undefined,
      'static'
    ).result.then(
      () => {
        this.countdownSubscription?.unsubscribe();
      },
      () => {
        this.countdownSubscription?.unsubscribe();
      }
    );
    this.requestAccessResponse = data;
    this.startCountdown();
  }

  getDetailsByOTP(
    foundApplication: IApplication,
    ngOtpInputRef: NgOtpInputComponent
  ) {
    this.subscriptions.add(
      this.applicationService
        .getApplicationDetailsByOTP(
          foundApplication.applicationNumber,
          this.otp
        )
        .subscribe({
          next: res => {
            this.modalService.dismissAll();
            this.applicationDetails = res.data;
            this.buildApplicationSummary(this.applicationDetails);
            this.viewMoreDetails = true;
            this.isConfirming = false;
            if (
              Object.prototype.hasOwnProperty.call(
                this.applicationDetails,
                'applicationCertificate'
              ) &&
              this.applicationDetails['applicationCertificate']
            ) {
              this.getSupportedLocales();
            }
          },
          error: () => {
            this.markControlsAsInValid(ngOtpInputRef);
            this.isConfirming = false;
          },
        })
    );
  }

  getDetails(foundApplication: IApplication) {
    this.subscriptions.add(
      this.applicationService
        .getApplicationDetailsByOTP(foundApplication.applicationNumber, '')
        .subscribe({
          next: res => {
            this.applicationDetails = res.data;
            this.buildApplicationSummary(this.applicationDetails);
            this.viewMoreDetails = true;
            if (
              Object.prototype.hasOwnProperty.call(
                this.applicationDetails,
                'applicationCertificate'
              ) &&
              this.applicationDetails['applicationCertificate']
            ) {
              this.getSupportedLocales();
            }
          },
          error: () => {
            this.showErrorToastMessage();
            this.requestingAccess = false;
          },
        })
    );
  }

  private buildApplicationSummary(applicationDetails: Record<string, unknown>) {
    const applicationSummary = applicationDetails[
      'applicationSummary'
    ] as Record<string, unknown>;
    const formDefinition = applicationDetails['formDefinition'] as Record<
      string,
      unknown
    >;
    this.applicationSummary = applicationFormBuildPreviewData(
      formDefinition['fields'] as FormlyFieldConfig[],
      applicationSummary
    );
  }

  showErrorToastMessage() {
    this.ts.show({
      body: 'Error has occurred, please try again',
      type: 'error',
      delay: 5000,
    });
  }

  getSupportedLocales() {
    this.localeService
      .getSupportedLocales()
      .pipe(
        map(res => {
          return this.filterLocales(res.data.content);
        })
      )
      .subscribe({
        next: response => {
          this.supportedLocales = response;
        },
        error: (error: any) => {
          this.ts.show({
            body: error.error.responseMessage ?? error.error.message,
            type: 'error',
            delay: 5000,
          });
        },
      });
  }

  getShortLocale(locale: string): string {
    if (locale === 'en-US') return 'gb';
    if (locale === 'sw') return 'tz';
    const shortLocale = locale.includes('-') ? locale.split('-')[1] : locale;
    return shortLocale.toLowerCase();
  }

  showSearch() {
    this.resetTrackApplication();
  }

  selectLocale(locale: string) {
    this.selectedCertLocale = locale;
  }

  private filterLocales(locales: ILocale[]): ILocale[] {
    if (this.applicationDetails) {
      const applicationCertificate = this.applicationDetails[
        'applicationCertificate'
      ] as IApplicationCertificate;
      const certificateLocales = Object.keys(
        applicationCertificate.certificateNames
      );
      locales = locales.filter(x => certificateLocales.includes(x.locale));
    }
    return locales;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.countdownSubscription?.unsubscribe();
  }
}
