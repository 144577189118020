import { Injectable } from '@angular/core';
import {
  AnalyticsBaseService,
  AnalyticsEventType,
  AnalyticsRoleUtils,
} from '@irembo-andela/irembogov3-common';
import { AnalyticsEvent } from '@irembo-andela/irembogov3-common';
import mixpanel, { Config } from 'mixpanel-browser';
import { environment } from '../../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { LocaleService } from '../locale.service';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService implements AnalyticsBaseService {
  constructor(
    private keycloakService: KeycloakService,
    private localeService: LocaleService
  ) {
    if (environment.IS_SENDING_ANALYTICS_EVENTS_ACTIVE) {
      this.initialize({
        token: environment.MIXPANEL_TOKEN,
        options: {
          ignore_dnt: true, // By default, Mixpanel will not track users with Do Not Track enabled. This option will override that behavior.
        },
      });
    }
  }

  initialize(config: { token: string; options?: Partial<Config> }) {
    try {
      mixpanel.init(config.token, config.options);
    } catch (error) {
      console.error('Error initializing Mixpanel:', error);
    }
  }

  EXCLUDED_EVENTS: AnalyticsEventType[] = [
    AnalyticsEventType.CLICK_SERVICE_CATEGORY,
    AnalyticsEventType.RESET_PASSWORD,
    AnalyticsEventType.CREATE_APPLICATION_ERROR,
    // ... Add more events to exclude here
  ];

  async trackEvent(
    eventData: Pick<AnalyticsEvent, 'eventType'> & Partial<AnalyticsEvent>
  ) {
    try {
      if (!environment.IS_SENDING_ANALYTICS_EVENTS_ACTIVE) return;

      // Check if the event type is in the excluded events list
      if (
        this.EXCLUDED_EVENTS.includes(eventData.eventType as AnalyticsEventType)
      ) {
        return;
      }

      const isAuthenticated = await this.keycloakService.isLoggedIn();
      const user = isAuthenticated
        ? await this.keycloakService.loadUserProfile()
        : null;
      const roles = isAuthenticated
        ? await this.keycloakService.getUserRoles()
        : [];
      const locale = await this.localeService.activeLocaleSubject.value;

      // Create standard properties
      const standardProperties = {
        user_id: user?.id,
        user_type: AnalyticsRoleUtils.getUserType(roles),
        user_role: AnalyticsRoleUtils.getUserRole(roles),
        language: locale?.locale || 'rw-RW',
        timestamp: new Date().toISOString(),
        distinct_id: user?.id,
      };

      // Extract metadata from eventData
      const { eventType, metadata, ...otherProps } = eventData;

      // Track the event with flattened properties
      mixpanel.track(eventType, {
        ...standardProperties,
        ...otherProps,
        ...metadata, // Spread metadata at root level
      });

      // Update user profile if authenticated
      if (isAuthenticated && user) {
        mixpanel.people.set({
          $email: user.email,
          $name: `${user.firstName} ${user.lastName}`,
          user_type: standardProperties.user_type,
          user_role: standardProperties.user_role,
          language: standardProperties.language,
        });
      }
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  }

  setUser(userId: string, userProperties?: Record<string, any>) {
    mixpanel.identify(userId);
    if (userProperties) {
      mixpanel.people.set(userProperties);
    }
  }

  reset() {
    mixpanel.reset();
  }
}
