import { Injectable } from '@angular/core';
import { AnalyticsService } from './analytics.service';
import { AnalyticsEvent } from '../../models/analytics/analytics-event.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsHooks {
  constructor(private analyticsService: AnalyticsService) {}

  withTracking<T>(
    event: Pick<AnalyticsEvent, 'eventType'> & Partial<AnalyticsEvent>,
    action: () => Promise<T> | T
  ): Promise<T> {
    return Promise.resolve()
      .then(() => this.analyticsService.trackEvent(event))
      .then(() => action())
      .catch(error => {
        this.analyticsService.trackEvent({
          ...event,
          eventType: `${event.eventType}_ERROR`,
          errorCode: error?.error?.responseCode || error?.status,
          errorMessage: error?.error?.responseMessage || error?.message,
        });
        throw error;
      });
  }
}
