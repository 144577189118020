import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { flatten } from 'flat';

export function genericUtilValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: Record<string, string> = {}
): ValidationErrors | null {
  try {
    const { prop1, prop2, comparator, validationName, operator } = options;
    if (!(prop1 && prop2 && comparator && validationName)) {
      return null;
    }
    const prop1Value = getGenericUtilValidatorPropValue(prop1, field, operator);
    const prop2Value = getGenericUtilValidatorPropValue(prop2, field, operator);

    let validation = false;
    switch (comparator) {
      case '=':
        validation = prop1Value == prop2Value;
        break;
      case '===':
        validation = prop1Value === prop2Value;
        break;
      case '!=':
        validation = prop1Value !== prop2Value;
        break;
      case '!==':
        validation = prop1Value !== prop2Value;
        break;
      case '<':
        validation = (prop1Value ?? '') < (prop2Value ?? '');
        break;
      case '<=':
        validation = (prop1Value ?? '') <= (prop2Value ?? '');
        break;
      case '>':
        validation = (prop1Value ?? '') > (prop2Value ?? '');
        break;
      case '>=':
        validation = (prop1Value ?? '') >= (prop2Value ?? '');
        break;
      case 'includes':
        validation = (<string>prop1Value).includes(<string>prop2Value);
        break;
      case 'notContains':
        validation = !(<string>prop1Value).includes(<string>prop2Value);
        break;
      case 'endsWith':
        validation = (<string>prop1Value).endsWith(<string>prop2Value);
        break;
      case 'startsWith':
        validation = (<string>prop1Value).startsWith(<string>prop2Value);
        break;
      case 'length':
        validation =
          (<string>prop1Value).length === Number.parseInt(<string>prop2Value);
        break;
      default:
        break;
    }

    if (validation) {
      return null;
    }

    const validationErrorObj: ValidationErrors = {};
    validationErrorObj[validationName] = true;
    return validationErrorObj;
  } catch (e: unknown) {
    return null;
  }
}

function getGenericUtilValidatorPropValue(
  prop: unknown,
  field: FormlyFieldConfig,
  operator?: string
): string | undefined | number | Record<string, unknown> {
  if (typeof prop === 'string' || typeof prop === 'number') {
    return getGenericUtilValidatorPropValueByOperator(prop, operator);
  }

  if (Array.isArray(prop)) {
    let propValue: any = field;

    prop.some((path: string) => {
      if ((<Record<string, unknown>>propValue)[path]) {
        propValue = (<Record<string, unknown>>propValue)[path];
        return false;
      }
      propValue = undefined;
      return true;
    });

    return getGenericUtilValidatorPropValueByOperator(propValue, operator);
  }
  return undefined;
}

function getGenericUtilValidatorPropValueByOperator(
  value: unknown,
  operation?: string
): any {
  try {
    switch (operation) {
      case 'parseInt':
        return Number.parseInt(<string>value, 10);
      case 'toLowerCase':
        return (<string>value).toLowerCase();
      case 'toUpperCase':
        return (<string>value).toUpperCase();
      default:
        return value;
    }
  } catch (e: any) {
    return value;
  }
}

export function uniqueRepeaterValuePropertyValidator(
  control: AbstractControl,
  field: FormlyFieldConfig,
  options: Record<string, string[]> = {}
): ValidationErrors | null {
  const propKeys: string[] | undefined = options['propKeys'];
  try {
    if (
      !propKeys ||
      !Array.isArray(propKeys) ||
      propKeys.length < 1 ||
      !control.value ||
      !Array.isArray(control.value) ||
      control.value.length < 1
    ) {
      return null;
    }

    let validation = false;

    propKeys.some((option: string) => {
      const validateProp: boolean = uniqueRepeaterValuePropHelper(
        control,
        option
      );
      if (validateProp) {
        validation = true;
        return true;
      }
      return false;
    });

    if (validation) {
      return { uniqueRepeaterValues: true };
    }
    return null;
  } catch (e: unknown) {
    return null;
  }
}

function uniqueRepeaterValuePropHelper(
  control: AbstractControl,
  property: string
): boolean {
  try {
    const repeaterData: any[] = control.value;
    let validation = false;
    const uniqueValueList: string[] = [];
    repeaterData.some(
      (itemValue: Record<string, unknown> | null | undefined) => {
        const flattenedValue: Record<string, unknown> = flatten(itemValue);
        if (!flattenedValue?.[property]) {
          return false;
        }
        if (uniqueValueList.includes(String(flattenedValue[property]))) {
          validation = true;
          return true;
        }
        uniqueValueList.push(String(flattenedValue[property]));
        return false;
      }
    );
    return validation;
  } catch (error: any) {
    return false;
  }
}
