import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import {
  FieldTypeConfig,
  FormlyFieldProps,
  FormlyFieldConfig,
} from '@ngx-formly/core';
import { distinctUntilChanged, filter } from 'rxjs';
import { IEnvironment } from '../../ui/models/environment.model';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { EGenericInputTriggerType } from '../../ui/models/irembo-generic-input-status.enum';
import { deepFlatten } from './deep-flatten.utils';
import { FormStateService } from '../../ui/services/formly/form-state.service';
import {
  ComparatorOptions,
  ComparatorOptionsConfig,
  ErrorMappingConfig,
  ValidationResult,
} from '../../ui/models/error-mapping.model';

export function updateUrlWithApiGatewayBaseUrl(
  url: string,
  environment: IEnvironment
): string {
  const portalApiGatewayBaseUrl: string | undefined =
    environment.apiGatewayBaseUrl;
  if (!portalApiGatewayBaseUrl) {
    throw new Error(
      'useBaseUrl: portal environment ApiGatewayBaseUrl property is required'
    );
  } else {
    url = `${portalApiGatewayBaseUrl}${url}`;
  }

  return url;
}

export function updateUrlWithMockBaseUrl(
  url: string,
  environment: IEnvironment
): string {
  const mockBaseUrl: string | undefined = environment.mockBaseUrl;
  if (!mockBaseUrl) {
    throw new Error(
      'useBaseUrl: portal environment mockBaseUrl property is required'
    );
  } else {
    url = `${mockBaseUrl}${url}`;
  }

  return url;
}

export function populateFormFields(
  data: unknown,
  field: FieldTypeConfig<
    | (FormlyFieldProps & {
        [additionalProperties: string]: any;
      })
    | undefined
  >,
  form: FormGroup<any> | FormArray<any>,
  serviceRef?: FormStateService
) {
  const fieldsToPopulates: {
    valueKey: string | string[];
    targetKey: string;
    separator: string;
  }[] = field.props?.['populates'] ?? [];
  let jsonData: Record<string, unknown> = {};

  try {
    if (data && typeof data === 'string') {
      jsonData = JSON.parse(data);
    }

    if (data && typeof data === 'object') {
      jsonData = data as Record<string, unknown>;
    }

    const flattenData = deepFlatten(jsonData) as Record<string, unknown>;
    for (const fieldToPopulate of fieldsToPopulates) {
      const formControl = form.get(fieldToPopulate['targetKey']);
      let value = '';

      if (Array.isArray(fieldToPopulate['valueKey'])) {
        const separator = fieldToPopulate['separator'] ?? ' ';
        const dataValue = fieldToPopulate['valueKey']
          .map(key => flattenData[key])
          .filter(value => value !== undefined)
          .join(separator);
        value = getValueAsParsedData(dataValue);
      } else {
        value = getValueAsParsedData(flattenData[fieldToPopulate['valueKey']]);
      }

      if (formControl) {
        if (formControl instanceof FormArray && Array.isArray(value)) {
          serviceRef?.intializeRepeater({
            items: value,
            key: fieldToPopulate['targetKey']?.split('.')?.pop() ?? '',
          });
        }
        formControl.reset();
        formControl.patchValue(value ?? null);
      }
    }
  } catch (error) {
    throw new Error('An error occurred while parsing response data');
  }
}

export function resetFieldsToPopulate(
  fieldsToPopulate: Record<string, string>[],
  form: FormGroup<any> | FormArray<any>,
  formStateService?: FormStateService
) {
  for (const fieldToPopulate of fieldsToPopulate) {
    const formControl = form.get(fieldToPopulate['targetKey']);
    if (formControl) {
      if (formControl instanceof FormArray) {
        formStateService?.intializeRepeater({
          key: fieldToPopulate['targetKey'],
          items: [],
        });
      } else {
        formControl.setValue(null);
      }
    }
  }
}

export function checkForValidFields(
  field: FieldTypeConfig<
    | (FormlyFieldProps & {
        [additionalProperties: string]: any;
      })
    | undefined
  >
) {
  if (field.props?.['populates']) {
    if (!Array.isArray(field.props?.['populates'])) {
      throw new Error('populates should be an array');
    }
  }
}

export function configureFields(
  field: FieldTypeConfig<
    | (FormlyFieldProps & {
        [additionalProperties: string]: any;
      })
    | undefined
  >,
  fieldsToPopulate: Record<string, string>[],
  form: FormGroup<any> | FormArray<any>
) {
  return field.formControl.valueChanges
    .pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    )
    .subscribe({
      next: () => {
        const resetFields = field.props['resetFields'] ?? true;
        if (field.formControl.invalid && resetFields) {
          resetFieldsToPopulate(fieldsToPopulate, form);
        }
      },
    });
}

const getValueAsParsedData = (value: unknown) => {
  try {
    return JSON.parse(<string>value);
  } catch (_err) {
    return value;
  }
};

export const getPopulateReferenceForm = (
  field: FormlyFieldConfig,
  form: FormGroup<any> | FormArray<any>
): FormGroup<any> | FormArray<any> => {
  if (field.props?.['populateRelativeToRootForm']) {
    let rootForm = form;
    while (rootForm.parent) {
      rootForm = rootForm.parent;
    }
    return rootForm;
  }

  return form;
};

export const subscribeToResetFieldFetchData = (
  field: FormlyFieldConfig,
  formStateService: FormStateService,
  fieldsToPopulate: Record<string, string>[],
  resetPopulateForm: FormGroup<any> | FormArray<any>
) => {
  field.options?.fieldChanges
    ?.pipe(
      filter((change: FormlyValueChangeEvent) => {
        return change.field.key === field.key;
      })
    )
    .subscribe((change: FormlyValueChangeEvent) => {
      if (
        !change.value ||
        change.field?.formControl?.invalid ||
        (change.type === 'hidden' && change.value)
      ) {
        formStateService.deleteFetchedDataKeyInFormState(<string>field.key);
        resetFieldsToPopulate(fieldsToPopulate, resetPopulateForm);
      }
    });
};

export const getValidDataFetchInputValue = (
  value: string | null,
  genericInputLength: number,
  inputLenghtAsMinLength = false,
  triggerType: EGenericInputTriggerType = EGenericInputTriggerType.INPUT_LENGTH
): string | null => {
  const formattedValue = value ? value.trim() : null;

  if (!formattedValue) {
    return null;
  }

  if (
    triggerType === EGenericInputTriggerType.TRIGGER_BUTTON ||
    triggerType === EGenericInputTriggerType.FIELD_LISTENER
  ) {
    return formattedValue;
  }

  if (
    genericInputLength === formattedValue.length ||
    (inputLenghtAsMinLength && formattedValue.length > genericInputLength)
  ) {
    return formattedValue;
  }

  return null;
};

export const getValidCrvsIdDataFetchInputValue = (
  value: string | null,
  regExp?: string
): string | null => {
  if (value && regExp && value.match(new RegExp(regExp))) {
    return value;
  }

  return null;
};

export const findFormControl = (
  form: AbstractControl,
  targetKey: string
): AbstractControl | null => {
  if (form instanceof FormControl) {
    return null;
  }

  const formGroup = form as FormGroup;

  // Direct check for the key
  if (formGroup.contains(targetKey)) {
    return formGroup.get(targetKey);
  }

  // Search through nested groups
  for (const key of Object.keys(formGroup.controls)) {
    const control = formGroup.get(key);
    if (control instanceof FormGroup) {
      const found = findFormControl(control, targetKey);
      if (found) {
        return found;
      }
    }
  }

  return null;
};

const getNestedValue = (obj: any, path: string): any =>
  path.split('.').reduce((value, key) => value?.[key], obj);

/**
 * Evaluates if a response contains an error based on multiple error detection rules.
 * @param response The response object to evaluate.
 * @param config The error mapping configuration.
 * @returns An object indicating if an error is present and the corresponding error code.
 */
export const isErrorResponse = (
  response: Record<string, any>,
  config: ErrorMappingConfig,
  isHttpError?: boolean
): { isError: boolean; errorCode?: string } => {
  if (!config) {
    return { isError: false, errorCode: undefined };
  }
  const detectionType = config.detectionType ?? 'simple';
  if (detectionType === 'simple') {
    const value = getNestedValue(response, config.errorMessagePath ?? '');
    if (!value) {
      return {
        isError: isHttpError ?? false,
        errorCode: undefined,
      };
    }

    return {
      isError: true,
      errorCode: String(value), // The error code will be matched against validation messages
    };
  }

  if (!config?.errorDetection || !Array.isArray(config.errorDetection)) {
    return {
      isError: isHttpError ?? false,
      errorCode: undefined,
    };
  }

  for (const detection of config.errorDetection) {
    const { path, expectedValue, errorCode, comparator, comparatorOptions } =
      detection;
    const value = getNestedValue(response, path);
    let hasError = false;

    if (comparator && comparatorOptions) {
      hasError = evaluateComparator(
        value,
        comparator,
        comparatorOptions,
        response
      );
    } else if (expectedValue !== undefined) {
      hasError = value === expectedValue;
    } else {
      hasError = Boolean(value);
    }

    if (hasError) {
      return { isError: true, errorCode };
    }
  }

  return {
    isError: isHttpError ?? false,
    errorCode: undefined,
  };
};

/**
 * Evaluates a comparator operation between two properties.
 * @param value The primary value to compare.
 * @param comparator The comparator operation to perform.
 * @param options Additional options for the comparator.
 * @param response The entire response object for nested property access.
 * @returns A boolean indicating the result of the comparison.
 */
const evaluateComparator = (
  value: any,
  comparator: ComparatorOptions,
  options: ComparatorOptionsConfig,
  response: Record<string, any>
): boolean => {
  const { prop1, prop2, operator } = options;

  // Retrieve prop1Value
  const prop1ValueFetched =
    typeof prop1 === 'string'
      ? getNestedValue(response, String(prop1))
      : undefined;
  const prop1Value =
    prop1ValueFetched !== undefined ? prop1ValueFetched : prop1;

  // Retrieve prop2Value
  const prop2ValueFetched =
    typeof prop2 === 'string'
      ? getNestedValue(response, String(prop2))
      : undefined;
  const prop2Value =
    prop2ValueFetched !== undefined ? prop2ValueFetched : prop2;

  // Apply operator transformations if any
  const processedProp1 = applyOperator(prop1Value, operator);
  const processedProp2 = applyOperator(prop2Value, operator);

  switch (comparator) {
    case '=':
      return processedProp1 == processedProp2;
    case '===':
      return processedProp1 === processedProp2;
    case '!=':
      return processedProp1 != processedProp2;
    case '!==':
      return processedProp1 !== processedProp2;
    case '<':
      return (processedProp1 ?? '') < (processedProp2 ?? '');
    case '<=':
      return (processedProp1 ?? '') <= (processedProp2 ?? '');
    case '>':
      return (processedProp1 ?? '') > (processedProp2 ?? '');
    case '>=':
      return (processedProp1 ?? '') >= (processedProp2 ?? '');
    case 'includes':
      return typeof processedProp1 === 'string' &&
        typeof processedProp2 === 'string'
        ? processedProp1.includes(processedProp2)
        : false;
    case 'notContains':
      return typeof processedProp1 === 'string' &&
        typeof processedProp2 === 'string'
        ? !processedProp1.includes(processedProp2)
        : false;
    case 'endsWith':
      return typeof processedProp1 === 'string' &&
        typeof processedProp2 === 'string'
        ? processedProp1.endsWith(processedProp2)
        : false;
    case 'startsWith':
      return typeof processedProp1 === 'string' &&
        typeof processedProp2 === 'string'
        ? processedProp1.startsWith(processedProp2)
        : false;
    case 'length':
      return (
        (typeof processedProp1 === 'string' || Array.isArray(processedProp1)) &&
        processedProp1.length === Number.parseInt(String(processedProp2), 10)
      );
    default:
      return false;
  }
};

/**
 * Applies an operator transformation to a value.
 * @param value The value to transform.
 * @param operation The operation to apply (e.g., 'parseInt', 'toLowerCase').
 * @returns The transformed value or the original value if the operation fails.
 */
const applyOperator = (value: unknown, operation?: string): any => {
  try {
    switch (operation) {
      case 'parseInt':
        return Number.parseInt(String(value), 10);
      case 'toLowerCase':
        return typeof value === 'string' ? value.toLowerCase() : value;
      case 'toUpperCase':
        return typeof value === 'string' ? value.toUpperCase() : value;
      default:
        return value;
    }
  } catch {
    return value;
  }
};

export const handleErrorMapping = (
  error: Record<string, any>,
  config: ErrorMappingConfig,
  defaultErrorMessageProp: string,
  field?: FormlyFieldConfig,
  control?: AbstractControl,
  isHttpError?: boolean
): ValidationResult => {
  if (!config) {
    return handleDefaultError(defaultErrorMessageProp, field);
  }

  const { isError, errorCode } = isErrorResponse(error, config, isHttpError);
  const validationKey = isError
    ? errorCode ?? config.defaultErrorCode
    : config.defaultErrorCode;
  const detectionType = config.detectionType ?? 'simple';
  const apiErrorMessage = config.errorMessagePath
    ? getNestedValue(error, config.errorMessagePath)
    : getNestedValue(error, defaultErrorMessageProp);

  if (detectionType === 'simple' && field?.validation?.messages) {
    return handleSimpleDetection(
      field,
      apiErrorMessage,
      validationKey,
      config,
      defaultErrorMessageProp
    );
  }

  if (errorCode) {
    return handleMappedError(field, validationKey);
  }

  if (config.useApiMessage && apiErrorMessage) {
    return handleApiMessage(field, apiErrorMessage);
  }

  return handleDefaultError(defaultErrorMessageProp, field);
};

const handleDefaultError = (
  defaultErrorMessageProp: string,
  field?: FormlyFieldConfig
): ValidationResult => {
  const errors: ValidationErrors = {
    [defaultErrorMessageProp]: {
      message: undefined,
    },
  };

  if (field) {
    ensureValidationMessages(field, defaultErrorMessageProp);
  }

  return {
    errors,
    statusClass: 'danger',
  };
};

const handleSimpleDetection = (
  field: FormlyFieldConfig,
  apiErrorMessage: string | undefined,
  validationKey: string,
  config: ErrorMappingConfig,
  defaultErrorMessageProp: string
): ValidationResult => {
  const matchedMessage = field.validation?.messages?.[apiErrorMessage ?? ''];

  if (matchedMessage) {
    ensureValidationMessages(field, validationKey);
    return {
      errors: {
        [apiErrorMessage!]: {
          message: undefined,
        },
      },
      statusClass: 'danger',
    };
  }

  ensureValidationMessages(field, validationKey);
  return {
    errors: {
      [config.defaultErrorCode ?? defaultErrorMessageProp]: {
        message: undefined,
      },
    },
    statusClass: 'danger',
  };
};

const handleMappedError = (
  field: FormlyFieldConfig | undefined,
  validationKey: string
): ValidationResult => {
  const errors: ValidationErrors = {
    [validationKey]: {
      message: undefined,
    },
  };

  if (field) {
    ensureValidationMessages(field, validationKey);
  }

  return {
    errors,
    statusClass: 'danger',
  };
};

const handleApiMessage = (
  field: FormlyFieldConfig | undefined,
  apiErrorMessage: string
): ValidationResult => {
  const apiValidationKey = 'apiError';
  const errors: ValidationErrors = {
    [apiValidationKey]: {
      message: apiErrorMessage,
    },
  };

  if (field) {
    ensureValidationMessages(field, apiValidationKey, apiErrorMessage);
  }

  return {
    errors,
    statusClass: 'danger',
  };
};

const ensureValidationMessages = (
  field: FormlyFieldConfig,
  validationKey: string,
  message?: string
): void => {
  if (!field.validation) field.validation = {};
  if (!field.validation.messages) field.validation.messages = {};
  if (!field.validation.messages[validationKey]) {
    field.validation.messages[validationKey] = () =>
      message ?? `${validationKey}`;
  }
};
