import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AnalyticsEventType,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import {
  ICategoryService,
  ICategoryServices,
} from 'src/app/models/service/category-services-response.model';
import { PublicServicesService } from '../../services/public-services.service';
import { BehaviorSubject, Subscription, distinctUntilChanged, map } from 'rxjs';
import { EServiceApplicantType } from '../../models/applicant-type.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'irembogov-category-services',
  templateUrl: './category-services.component.html',
  styleUrls: ['./category-services.component.scss'],
})
export class CategoryServicesComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedApplicantType = 'individual';
  @Output() showServiceDetails: EventEmitter<ICategoryService> =
    new EventEmitter();
  defaultCategory = { categoryId: '0', categoryName: 'All categories' };
  selectedCategory = this.defaultCategory;
  categoryServices: ICategoryServices[] = [];
  selectedCategoryServices: ICategoryService[] = [];
  isLoadingCategoryServices = false;
  subscription: Subscription = new Subscription();
  screenWidth = new BehaviorSubject(window.innerWidth);

  analyticsEventType = AnalyticsEventType;

  isMobile$ = this.screenWidth.pipe(
    map(width => width <= 568), // Assuming a breakpoint at 767px for mobile view
    distinctUntilChanged()
  );

  constructor(
    private publicService: PublicServicesService,
    private toastService: ToastService,
    private translateService: TranslateService
  ) {
    window.addEventListener('resize', () => {
      this.screenWidth.next(window.innerWidth);
    });
  }

  ngOnInit(): void {
    this.getCategoryServices();
    this.subscription.add(
      this.translateService.onLangChange.subscribe(() => {
        this.getCategoryServices();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedApplicantType']) {
      this.getCategoryServices();
    }
  }

  selectCategory(category: { categoryId: string; categoryName: string }) {
    this.selectedCategory = category;
    this.selectedCategoryServices =
      this.categoryServices.find(cat => cat.categoryId === category.categoryId)
        ?.services ?? [];
  }

  getCategoryServices() {
    const applicantType = [
      EServiceApplicantType.BOTH,
      this.selectedApplicantType.toUpperCase(),
    ];
    this.isLoadingCategoryServices = true;
    this.subscription.add(
      this.publicService.getCategoryServices(applicantType).subscribe({
        next: response => {
          this.categoryServices = response.data;
          this.isLoadingCategoryServices = false;
          this.scrollCheck();
        },
        error: err => {
          this.toastService.show({
            body: err.error.message,
            type: 'error',
            delay: 5000,
          });
          this.isLoadingCategoryServices = false;
        },
      })
    );
  }

  onServiceClicked(service: ICategoryService) {
    this.showServiceDetails.emit(service);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // Slider stuff
  @ViewChild('scrlCategory', { read: ElementRef }) scrl!: ElementRef<any>;

  scrollX = 0;
  scrollEnd = false;

  scroll(shift: number) {
    if (this.scrl) {
      this.scrl.nativeElement.scrollTo({
        left: shift,
        behavior: 'smooth',
      });

      this.scrl.nativeElement.scrollLeft += shift;
      this.scrollX += shift;

      this.scrollCheck();
    }
  }

  scrollCheck() {
    if (this.scrl) {
      this.scrollX = this.scrl.nativeElement.scrollLeft;
      this.scrollEnd =
        Math.floor(
          this.scrl.nativeElement.scrollWidth -
            this.scrl.nativeElement.scrollLeft
        ) <= this.scrl.nativeElement.offsetWidth;
    }
  }
}
