import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FieldArrayType } from '@ngx-formly/core';
import { FormlyValueChangeEvent } from '@ngx-formly/core/lib/models';
import { TranslateService } from '@ngx-translate/core';
import { distinctUntilChanged, filter, Subscription } from 'rxjs';
import { FormStateService } from '../../../services/formly/form-state.service';

@Component({
  selector: 'irembogov-custom-repeater',
  templateUrl: './custom-repeater.component.html',
  styleUrls: ['./custom-repeater.component.scss'],
})
export class CustomRepeaterComponent
  extends FieldArrayType
  implements OnInit, AfterViewInit, OnDestroy
{
  private readonly subscriptions = new Subscription();
  private readonly minItemsValidationkey = 'minItems';
  private readonly maxItemsValidationkey = 'maxItems';
  private readonly uniqueRepeaterValuesValidationkey = 'uniqueRepeaterValues';

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly formStateService: FormStateService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  checkIfMaxLengthLimitReached(): boolean {
    if (this.field.props?.[this.maxItemsValidationkey]) {
      return (
        (this.field.fieldGroup?.length ?? 0) >=
        Number.parseInt(this.field.props[this.maxItemsValidationkey], 10)
      );
    }
    return false;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.formStateService.intializeRepeaterSource$
        .pipe(
          filter(res => res?.['key'] === this.field.key),
          distinctUntilChanged(
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
          )
        )
        .subscribe(res => {
          if (this.field.key === res['key']) {
            while (this.formControl.length !== 0) {
              this.formControl.removeAt(0);
            }
            this.formControl.clear();

            if (this.field.fieldGroup?.length) {
              for (let i = this.field.fieldGroup.length - 1; i >= 0; i--) {
                this.remove(i);
              }
            }

            this.formControl.controls.length = 0;

            if (Array.isArray(res['items']) && res['items'].length > 0) {
              res['items'].forEach(() => {
                this.add();
              });
            }
          }
          this.cd.markForCheck();
        })
    );
  }

  ngAfterViewInit(): void {
    if (!this.field.props?.[this.minItemsValidationkey]) {
      this.deleteExistingValidatorByKey(this.minItemsValidationkey);
    } else {
      this.setMinItemValidation();
    }

    if (!this.field.props?.[this.minItemsValidationkey]) {
      this.deleteExistingValidatorByKey(this.maxItemsValidationkey);
    } else {
      this.setMaxItemValidation();
    }

    this.checkUniqueRepeaterValuesProps();

    this.subscriptions.add(
      this.field.options?.fieldChanges
        ?.pipe(
          filter((change: FormlyValueChangeEvent) => {
            return (
              change.field.key === this.field.key &&
              change.type === 'expressionChanges' &&
              (change['property'] === `props.${this.minItemsValidationkey}` ||
                change['property'] === `props.${this.maxItemsValidationkey}` ||
                change['property'] ===
                  `props.${this.uniqueRepeaterValuesValidationkey}`)
            );
          })
        )
        .subscribe((change: FormlyValueChangeEvent) => {
          if (change['property'] === `props.${this.minItemsValidationkey}`) {
            this.setMinItemValidation();
          }
          if (change['property'] === `props.${this.maxItemsValidationkey}`) {
            this.setMaxItemValidation();
          }
          if (
            change['property'] ===
            `props.${this.uniqueRepeaterValuesValidationkey}`
          ) {
            this.checkUniqueRepeaterValuesProps();
          }
        })
    );
  }

  deleteExistingValidatorByKey(validationKey: string): void {
    this.field.validators?.validation?.forEach(
      (
        validation: { name: string; options: Record<string, unknown> },
        index: number
      ) => {
        if (
          validation.name === validationKey ||
          (validation.name === 'genericUtilValidator' &&
            validation.options?.['validationName'] === validationKey)
        ) {
          this.field.validators?.validation?.splice(index, 1);
        }
      }
    );
  }

  setMaxItemValidation(): void {
    const maxItemsValue = Number.parseInt(
      this.field.props?.[this.maxItemsValidationkey],
      10
    );
    if (!maxItemsValue) {
      return;
    }

    if (this.field.props?.[this.minItemsValidationkey]) {
      const minItemsValue = Number.parseInt(
        this.field.props?.[this.minItemsValidationkey],
        10
      );
      if (minItemsValue > maxItemsValue) {
        delete this.field.props?.[this.minItemsValidationkey];
      }
    }
    this.deleteExistingValidatorByKey(this.maxItemsValidationkey);

    this.setMaxOrMinItemsValidations(
      maxItemsValue,
      '>=',
      this.maxItemsValidationkey,
      'Maximum items exceeded.'
    );
  }

  setMinItemValidation(): void {
    const minItemsValue = Number.parseInt(
      this.field.props?.[this.minItemsValidationkey],
      10
    );
    if (!minItemsValue) {
      return;
    }
    if (this.field.props?.[this.maxItemsValidationkey]) {
      const maxItemsValue = Number.parseInt(
        this.field.props?.[this.maxItemsValidationkey],
        10
      );
      if (minItemsValue > maxItemsValue) {
        delete this.field.props?.[this.minItemsValidationkey];
        return;
      }
    }

    this.deleteExistingValidatorByKey(this.minItemsValidationkey);
    this.setMaxOrMinItemsValidations(
      minItemsValue,
      '<=',
      this.minItemsValidationkey,
      'Minimum items not met'
    );
  }

  setMaxOrMinItemsValidations(
    minMaxValue: number,
    comparator: '<=' | '>=',
    validationName: string,
    validationDefaultMessage: string
  ): void {
    const minMaxItemValidation = {
      name: 'genericUtilValidator',
      options: {
        prop1: minMaxValue,
        comparator: comparator,
        prop2: ['fieldGroup', 'length'],
        validationName,
        operator: 'parseInt',
      },
    };
    this.addValidationWithDefaultMessage(
      validationName,
      minMaxItemValidation,
      validationDefaultMessage
    );
  }

  checkUniqueRepeaterValuesProps() {
    if (
      this.field.props?.[this.uniqueRepeaterValuesValidationkey] &&
      Array.isArray(this.field.props[this.uniqueRepeaterValuesValidationkey])
    ) {
      this.deleteExistingValidatorByKey(this.uniqueRepeaterValuesValidationkey);

      const uniqueRepeaterValuesValidation = {
        name: this.uniqueRepeaterValuesValidationkey,
        options: {
          propKeys: this.field.props?.[this.uniqueRepeaterValuesValidationkey],
        },
      };

      this.addValidationWithDefaultMessage(
        'uniqueRepeaterValues',
        uniqueRepeaterValuesValidation,
        'Values in the repeater field must be unique.'
      );
    } else {
      this.deleteExistingValidatorByKey(this.uniqueRepeaterValuesValidationkey);
    }
  }

  addValidationWithDefaultMessage(
    validationName: string,
    validation: { name: string; options: Record<string, unknown> },
    validationDefaultMessage: string
  ) {
    // add validation
    if (!this.field.validators) {
      this.field.validators = {};
    }

    if (!this.field.validators.validation) {
      this.field.validators.validation = [];
    }
    this.field.validators.validation.push(validation);

    // add validation message
    if (this.field.validation?.messages?.[validationName]) {
      return;
    }

    const messageData: Record<string, string> = {};
    messageData[validationName] = this.translateService.instant(
      validationDefaultMessage
    );

    if (!this.field.validation) {
      this.field.validation = {};
    }
    this.field.validation['messages'] = {
      ...this.field.validation?.messages,
      ...messageData,
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
