import { NgModule } from '@angular/core';
import {
  CommonModule,
  DatePipe,
  KeyValuePipe,
  NgForOf,
  NgIf,
} from '@angular/common';
import { RouterModule } from '@angular/router';
import { IremboDatePickerComponent } from './components/irembo-date-picker/irembo-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbModule,
  NgbToastModule,
} from '@ng-bootstrap/ng-bootstrap';
import { IremboSelectComponent } from './components/irembo-select/irembo-select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { IremboSectionMessageComponent } from './components/irembo-section-message/irembo-section-message.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { IrembogovStepperComponent } from './components/irembogov-stepper/irembogov-stepper.component';
import { IrembogovStepComponent } from './components/irembogov-step/irembogov-step.component';
import { IremboRightFlyoutSidebarWrapperComponent } from './components/irembo-right-flyout-sidebar/irembo-right-flyout-sidebar.component';
import { IrembogovNavbarComponent } from './components/irembogov-navbar/irembogov-navbar.component';
import { IrembogovLeftSidebarComponent } from './components/irembogov-left-sidebar/irembogov-left-sidebar.component';
import { NavbarProfileSectionComponent } from './components/navbar-profile-section/navbar-profile-section.component';
import { IremboToastComponent } from './components/irembo-toast/irembo-toast.component';
import { IremboPaginationComponent } from './components/irembo-pagination/irembo-pagination.component';
import { IremboOffcanvasComponent } from './components/irembo-offcanvas/irembo-offcanvas.component';
import { IremboPhoneNumberComponent } from './components/irembo-phone-number/irembo-phone-number.component';
import { ButtonGroupComponent } from './components/button-group/button-group.component';
import { IrembogovSortOptionsDropdownComponent } from './components/irembogov-sort-options-dropdown/irembogov-sort-options-dropdown.component';
import { TableInlineUserInfoComponent } from './components/table-inline-user-info/table-inline-user-info.component';
import { IrembogovTableWrapperComponent } from './components/irembogov-table-wrapper/irembogov-table-wrapper.component';
import { LoadingCircleSpinnerComponent } from './components/irembo-loading-circle-spinner/irembo-loading-circle-spinner.component';
import { PrettyjsonPipe } from './pipes/prettyjson.pipe';
import { CustomDropDownComponent } from './components/formly/custom-drop-down/custom-drop-down.component';
import { FormlyModule } from '@ngx-formly/core';
import { CustomDateParserFormatterService } from './services/custom-date-picker/custom-date-parser-formatter.service';
import { CustomDateAdapterService } from './services/custom-date-picker/custom-date-adapter.service';
import { CustomDatePickerComponent } from './components/formly/custom-date-picker/custom-date-picker.component';
import { CustomFieldStepperComponent } from './components/formly/custom-field-stepper/custom-field-stepper.component';
import { ApplicationFormSectionComponent } from './components/formly/application-form-section/application-form-section.component';
import { CustomVerticalStepperComponent } from './components/formly/custom-vertical-stepper/custom-vertical-stepper.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { CustomPhoneNumberComponent } from './components/formly/custom-phone-number/custom-phone-number.component';
import { IremboTimePickerComponent } from './components/irembo-time-picker/irembo-time-picker.component';
import { CustomTimePickerComponent } from './components/formly/custom-time-picker/custom-time-picker.component';
import { IremboIdInputComponent } from './components/irembo-id-input/irembo-id-input.component';
import { CustomIdInputComponent } from './components/formly/custom-id-input/custom-id-input.component';
import { IrembogovSearchboxComponent } from './components/irembogov-searchbox/irembogov-searchbox.component';
import { IrembogovBrowseButtonComponent } from './components/irembogov-browse-button/irembogov-browse-button.component';
import { IrembogovFeaturedServiceCardComponent } from './components/irembogov-featured-service-card/irembogov-featured-service-card.component';
import { IrembogovServicesBrowseByPanelComponent } from './components/irembogov-services-browse-by-panel/irembogov-services-browse-by-panel.component';
import { IrembogovBrowseByServiceCardComponent } from './components/irembogov-browse-by-service-card/irembogov-browse-by-service-card.component';
import { IremboCascadingDropDownsComponent } from './components/irembo-cascading-drop-downs/irembo-cascading-drop-downs.component';
import { CustomCascadingDropDownsComponent } from './components/formly/custom-cascading-drop-downs/custom-cascading-drop-downs.component';
import { IremboFileUploadComponent } from './components/irembo-file-upload/irembo-file-upload.component';
import { CustomFileUploadComponent } from './components/formly/custom-file-upload/custom-file-upload.component';
import { IrembogovFooterComponent } from './components/irembogov-footer/irembogov-footer.component';
import { IrembogovPublicServiceNotFoundComponent } from './components/irembogov-public-service-not-found/irembogov-public-service-not-found.component';
import { IrembogovAdvanceSearchOptionComponent } from './components/irembogov-advance-search-option/irembogov-advance-search-option.component';
import { IrembogovBasicToggleSwitchComponent } from './components/irembogov-basic-toggle-switch/irembogov-basic-toggle-switch.component';
import { IrembogovFormcontrolToggleSwitchComponent } from './components/irembogov-formcontrol-toggle-switch/irembogov-formcontrol-toggle-switch.component';
import { CustomRepeaterComponent } from './components/formly/custom-repeater/custom-repeater.component';
import { HideFieldWrapperComponent } from './components/formly/hide-field-wrapper/hide-field-wrapper.component';
import { ApplicationTrackerComponent } from './components/irembogov-application-tracker/application-tracker.component';
import { ApplicationSummaryComponent } from './components/irembogov-application-summary/application-summary.component';
import { ApplicationFormPreviewCardComponent } from './components/irembogov-application-form-preview-card/application-form-preview-card.component';
import { ApplicationFormFieldCardComponent } from './components/irembogov-application-form-field-card/application-form-field-card.component';
import { CustomLabelValueRepeaterComponent } from './components/formly/custom-label-value-repeater/custom-label-value-repeater.component';
import { HintFieldWrapperComponent } from './components/formly/hint-field-wrapper/hint-field-wrapper.component';
import { ReadonlyWrapperComponent } from './components/formly/wrappers/formly-readonly-wrapper/formly-readonly-wrapper.component';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomReportContainerItemComponent } from './components/reporting/custom-report-container-item/custom-report-container-item.component';
import { CustomReportContainerComponent } from './components/reporting/custom-report-container/custom-report-container.component';
import { CustomReportFiltersComponent } from './components/reporting/custom-report-filters/custom-report-filters.component';
import { CustomReportHistogramComponent } from './components/reporting/custom-report-histogram/custom-report-histogram.component';
import { CustomReportLineChartComponent } from './components/reporting/custom-report-line-chart/custom-report-line-chart.component';
import { CustomReportStatisticsCardComponent } from './components/reporting/custom-report-statistics-card/custom-report-statistics-card.component';
import { CustomReportTableComponent } from './components/reporting/custom-report-table/custom-report-table.component';
import { MonthPickerComponent } from './components/reporting/date-picker-components/month-picker/month-picker.component';
import { RegularDatePickerComponent } from './components/reporting/date-picker-components/regular-date-picker/regular-date-picker.component';
import { YearPickerComponent } from './components/reporting/date-picker-components/year-picker/year-picker.component';
import { ReportCardComponent } from './components/reporting/report-card/report-card.component';
import { NgChartsModule } from 'ng2-charts';
import { CustomReportingDatePickerComponent } from './components/reporting/custom-date-picker/custom-date-picker.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CustomDropdownPaginatedComponent } from './components/formly/custom-dropdown-paginated/custom-dropdown-paginated.component';
import { IremboSelectPaginatedComponent } from './components/irembo-select-paginated/irembo-select-paginated.component';
import { IremboApplicationNumberInputComponent } from './components/irembo-application-number-input/irembo-application-number-input.component';
import { CustomApplicationNumberInputComponent } from './components/formly/custom-application-number-input/custom-application-number-input.component';
import { IrembogovGenericDataFetchComponent } from './components/irembogov-generic-data-fetch/irembogov-generic-data-fetch.component';
import { CustomGenericDataFetchComponent } from './components/formly/custom-generic-data-fetch/custom-generic-data-fetch.component';
import { CustomDoubleIdInputComponent } from './components/formly/custom-double-id-input/custom-double-id-input.component';
import { IremboDoubleIdInputComponent } from './components/irembo-double-id-input/irembo-double-id-input.component';
import { IrembogovCrvsIdInputFetchWidgetComponent } from './components/irembogov-crvs-id-input-fetch-widget/irembogov-crvs-id-input-fetch-widget.component';
import { CustomCrvsIdInputFetchWidgetComponent } from './components/formly/custom-crvs-id-input-fetch-widget/custom-crvs-id-input-fetch-widget.component';
import {
  MissingTranslationHandler,
  TranslateModule,
} from '@ngx-translate/core';
import { IremboGenericFileUploadComponent } from './components/irembo-generic-file-upload/irembo-generic-file-upload.component';
import { FileSizePipe } from './pipes/file-size.pipe';
import { IrembogovApplicationGenericFormFieldCardComponent } from './components/irembogov-application-generic-form-field-card/irembogov-application-generic-form-field-card.component';
import { IrembogovApplicationGenericFormPreviewCardComponent } from './components/irembogov-application-generic-form-preview-card/irembogov-application-generic-form-preview-card.component';
import { IremboTinInputComponent } from './components/irembo-tin-input/irembo-tin-input.component';
import { CustomTinInputComponent } from './components/formly/custom-tin-input/custom-tin-input.component';
import { IremboTimeValuePickerComponent } from './components/irembo-time-value-picker/irembo-time-value-picker.component';
import { CustomSectionMessageWithLinkComponent } from './components/formly/custom-section-message-with-link/custom-section-message-with-link.component';
import { IremboFileUploadSelectorComponent } from './components/irembo-file-upload-selector/irembo-file-upload-selector.component';
import { IremboCustomPaginationComponent } from './components/irembo-custom-pagination/irembo-custom-pagination.component';
import { IremboDateRangeSelectorComponent } from './components/irembo-date-range-selector/irembo-date-range-selector.component';
import { IremboDateRangeSelectorFooterComponent } from './components/irembo-date-range-selector/irembo-date-range-selector-footer/irembo-date-range-selector-footer.component';
import { IrembogovLegalPagesComponent } from './components/irembogov-legal-pages/irembogov-legal-pages.component';
import { IrembogovTermsOfUseComponent } from './components/irembogov-terms-of-use/irembogov-terms-of-use.component';
import { IrembogovPrivacyPolicyComponent } from './components/irembogov-privacy-policy/irembogov-privacy-policy.component';
import { IrembogovDisclaimerComponent } from './components/irembogov-disclaimer/irembogov-disclaimer.component';
import { IremboMissingTranslationHandler } from '../utils/utils/missing-translations-handler';
import { RfaReadonlyCheckerWrapperComponent } from './components/formly/rfa-readonly-checker-wrapper/rfa-readonly-checker-wrapper.component';
import { IrembogovSlotBookingComponent } from './components/irembogov-slot-booking/irembogov-slot-booking.component';
import { CustomSlotBookingComponent } from './components/formly/custom-slot-booking/custom-slot-booking.component';
import { NonLoggedInApplicationFormFieldCardComponent } from './components/irembogov-non-loggedin-application-form-field-card/non-loggedin-application-form-field-card.component';
import { NonLoggedInApplicationFormPreviewCardComponent } from './components/irembogov-non-loggedin-application-form-preview-card/non-loggedin-application-form-preview-card.component';
import { TooltipFieldWrapperComponent } from './components/formly/tooltip-field-wrapper/tooltip-field-wrapper.component';
import { ButtonTriggerWrapperComponent } from './components/formly/wrappers/button-trigger-wrapper/button-trigger-wrapper.component';
import { CustomOtpDataFetchComponent } from './components/formly/custom-otp-data-fetch/custom-otp-data-fetch.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { IremboOtpDataFetchComponent } from './components/irembo-otp-data-fetch-input/irembo-otp-data-fetch-input.component';
import { CustomDateTimePickerComponent } from './components/formly/custom-date-time-picker/custom-date-time-picker.component';
import { IremboDateTimePickerComponent } from './components/irembo-date-time-picker/irembo-date-time-picker.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { CustomFieldTabsComponent } from './components/formly/custom-field-tabs/custom-field-tabs.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CustomExpansionRepeaterComponent } from './components/formly/custom-expansion-repeater/custom-expansion-repeater.component';
import { CustomExpansionPanelWithIconComponent } from './components/formly/wrappers/formly-panel-wrapper/formly-panel-wrapper.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngJsoneditorModule } from '@maaxgr/ang-jsoneditor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IrembogovFormlyGlobalErrorComponent } from './components/formly/irembogov-formly-global-error/irembogov-formly-global-error.component';

import { TrackEventDirective } from './services/analytics/track-event.directive';
import { AnalyticsService } from './services/analytics/analytics.service';
import { AnalyticsHooks } from './services/analytics/analytics.hooks';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    NgSelectModule,
    CdkStepperModule,
    RouterModule,
    NgbToastModule,
    FormlyModule.forRoot(),
    PdfViewerModule,
    MatDatepickerModule,
    NgOtpInputModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgChartsModule,
    TranslateModule.forChild({
      useDefaultLang: true,
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: IremboMissingTranslationHandler,
      },
    }),
    DatePipe,
    KeyValuePipe,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    MatDialogModule,
    MatTabsModule,
    MatExpansionModule,
    MatIconModule,
    MatSidenavModule,
    MatTooltipModule,
    AngJsoneditorModule,
    DragDropModule,
  ],
  declarations: [
    IremboDatePickerComponent,
    IremboDateTimePickerComponent,
    IremboDateRangeSelectorComponent,
    IremboDateRangeSelectorFooterComponent,
    IremboSectionMessageComponent,
    IremboSelectComponent,
    IrembogovStepperComponent,
    IrembogovStepComponent,
    IremboRightFlyoutSidebarWrapperComponent,
    IremboOffcanvasComponent,
    IrembogovNavbarComponent,
    IrembogovLeftSidebarComponent,
    NavbarProfileSectionComponent,
    IremboToastComponent,
    IremboPaginationComponent,
    IrembogovTableWrapperComponent,
    IremboOffcanvasComponent,
    IremboPhoneNumberComponent,
    ButtonGroupComponent,
    IrembogovSortOptionsDropdownComponent,
    TableInlineUserInfoComponent,
    LoadingCircleSpinnerComponent,
    PrettyjsonPipe,
    CustomDropDownComponent,
    CustomDatePickerComponent,
    CustomDateTimePickerComponent,
    CustomFieldStepperComponent,
    ApplicationFormSectionComponent,
    CustomVerticalStepperComponent,
    ToggleButtonComponent,
    CustomPhoneNumberComponent,
    IremboTimePickerComponent,
    IremboTimeValuePickerComponent,
    CustomTimePickerComponent,
    IremboIdInputComponent,
    CustomIdInputComponent,
    CustomDoubleIdInputComponent,
    IremboDoubleIdInputComponent,
    IrembogovSearchboxComponent,
    IrembogovBrowseButtonComponent,
    IrembogovFeaturedServiceCardComponent,
    IrembogovServicesBrowseByPanelComponent,
    IrembogovBrowseByServiceCardComponent,
    IremboCascadingDropDownsComponent,
    CustomCascadingDropDownsComponent,
    IremboFileUploadComponent,
    IremboGenericFileUploadComponent,
    CustomFileUploadComponent,
    IrembogovFooterComponent,
    IrembogovPublicServiceNotFoundComponent,
    IrembogovAdvanceSearchOptionComponent,
    IrembogovBasicToggleSwitchComponent,
    IrembogovFormcontrolToggleSwitchComponent,
    CustomRepeaterComponent,
    HideFieldWrapperComponent,
    RfaReadonlyCheckerWrapperComponent,
    ApplicationTrackerComponent,
    ApplicationSummaryComponent,
    ApplicationFormPreviewCardComponent,
    ApplicationFormFieldCardComponent,
    CustomLabelValueRepeaterComponent,
    HintFieldWrapperComponent,
    ReadonlyWrapperComponent,
    ButtonTriggerWrapperComponent,
    CustomDatePickerComponent,
    CustomDateTimePickerComponent,
    CustomReportContainerItemComponent,
    CustomReportContainerComponent,
    CustomReportStatisticsCardComponent,
    CustomReportLineChartComponent,
    CustomReportHistogramComponent,
    CustomReportFiltersComponent,
    CustomReportTableComponent,
    ReportCardComponent,
    MonthPickerComponent,
    YearPickerComponent,
    RegularDatePickerComponent,
    CustomReportingDatePickerComponent,
    IremboSelectPaginatedComponent,
    CustomDropdownPaginatedComponent,
    IremboApplicationNumberInputComponent,
    CustomApplicationNumberInputComponent,
    IrembogovGenericDataFetchComponent,
    CustomGenericDataFetchComponent,
    CustomOtpDataFetchComponent,
    IremboOtpDataFetchComponent,
    IrembogovCrvsIdInputFetchWidgetComponent,
    CustomCrvsIdInputFetchWidgetComponent,
    FileSizePipe,
    IrembogovApplicationGenericFormPreviewCardComponent,
    IrembogovApplicationGenericFormFieldCardComponent,
    IremboTinInputComponent,
    CustomTinInputComponent,
    CustomSectionMessageWithLinkComponent,
    IremboFileUploadSelectorComponent,
    IremboCustomPaginationComponent,
    IrembogovLegalPagesComponent,
    IrembogovTermsOfUseComponent,
    IrembogovPrivacyPolicyComponent,
    IrembogovDisclaimerComponent,
    IrembogovSlotBookingComponent,
    CustomSlotBookingComponent,
    NonLoggedInApplicationFormFieldCardComponent,
    NonLoggedInApplicationFormPreviewCardComponent,
    TooltipFieldWrapperComponent,
    CustomFieldTabsComponent,
    CustomExpansionRepeaterComponent,
    CustomExpansionPanelWithIconComponent,
    IrembogovFormlyGlobalErrorComponent,
    TrackEventDirective,
  ],
  exports: [
    IremboDatePickerComponent,
    IremboDateTimePickerComponent,
    IremboDateRangeSelectorComponent,
    IremboDateRangeSelectorFooterComponent,
    IremboSectionMessageComponent,
    IremboSelectComponent,
    IrembogovStepperComponent,
    IrembogovStepComponent,
    IremboRightFlyoutSidebarWrapperComponent,
    IremboOffcanvasComponent,
    IrembogovNavbarComponent,
    IrembogovLeftSidebarComponent,
    NavbarProfileSectionComponent,
    IremboToastComponent,
    IrembogovTableWrapperComponent,
    IremboPaginationComponent,
    IremboToastComponent,
    IremboOffcanvasComponent,
    IremboPhoneNumberComponent,
    ButtonGroupComponent,
    IrembogovSortOptionsDropdownComponent,
    TableInlineUserInfoComponent,
    LoadingCircleSpinnerComponent,
    PrettyjsonPipe,
    CustomDropDownComponent,
    CustomDatePickerComponent,
    CustomVerticalStepperComponent,
    ToggleButtonComponent,
    CustomPhoneNumberComponent,
    IremboTimePickerComponent,
    IremboTimeValuePickerComponent,
    CustomTimePickerComponent,
    IremboIdInputComponent,
    CustomIdInputComponent,
    CustomDoubleIdInputComponent,
    IremboDoubleIdInputComponent,
    IrembogovSearchboxComponent,
    IrembogovBrowseButtonComponent,
    IrembogovFeaturedServiceCardComponent,
    IrembogovServicesBrowseByPanelComponent,
    IrembogovBrowseByServiceCardComponent,
    IremboCascadingDropDownsComponent,
    CustomCascadingDropDownsComponent,
    IremboFileUploadComponent,
    IremboGenericFileUploadComponent,
    CustomFileUploadComponent,
    IrembogovFooterComponent,
    IrembogovPublicServiceNotFoundComponent,
    IrembogovAdvanceSearchOptionComponent,
    IrembogovBasicToggleSwitchComponent,
    HideFieldWrapperComponent,
    RfaReadonlyCheckerWrapperComponent,
    ApplicationTrackerComponent,
    ApplicationSummaryComponent,
    ApplicationFormPreviewCardComponent,
    ApplicationFormFieldCardComponent,
    HintFieldWrapperComponent,
    ReadonlyWrapperComponent,
    ButtonTriggerWrapperComponent,
    CustomDatePickerComponent,
    CustomReportContainerItemComponent,
    CustomReportContainerComponent,
    CustomReportStatisticsCardComponent,
    CustomReportLineChartComponent,
    CustomReportHistogramComponent,
    CustomReportFiltersComponent,
    CustomReportTableComponent,
    ReportCardComponent,
    MonthPickerComponent,
    YearPickerComponent,
    RegularDatePickerComponent,
    CustomReportingDatePickerComponent,
    IremboSelectPaginatedComponent,
    CustomDropdownPaginatedComponent,
    IremboApplicationNumberInputComponent,
    CustomApplicationNumberInputComponent,
    IrembogovGenericDataFetchComponent,
    CustomGenericDataFetchComponent,
    CustomOtpDataFetchComponent,
    IremboOtpDataFetchComponent,
    IrembogovApplicationGenericFormPreviewCardComponent,
    IrembogovApplicationGenericFormFieldCardComponent,
    IremboTinInputComponent,
    CustomTinInputComponent,
    CustomSectionMessageWithLinkComponent,
    IremboFileUploadSelectorComponent,
    IremboCustomPaginationComponent,
    IrembogovLegalPagesComponent,
    IrembogovSlotBookingComponent,
    CustomSlotBookingComponent,
    NonLoggedInApplicationFormFieldCardComponent,
    NonLoggedInApplicationFormPreviewCardComponent,
    TooltipFieldWrapperComponent,
    CustomFieldTabsComponent,
    CustomExpansionRepeaterComponent,
    CustomExpansionPanelWithIconComponent,
    TrackEventDirective,
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useClass: CustomDateParserFormatterService,
    },
    {
      provide: NgbDateAdapter,
      useClass: CustomDateAdapterService,
    },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    AnalyticsService,
    AnalyticsHooks,
  ],
})
export class UiModule {}
