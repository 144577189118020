export enum AnalyticsEventType {
  // Application Category Events
  CLICK_APPLICANT_TYPE = 'CLICK_APPLICANT_TYPE',
  CLICK_SERVICE_CATEGORY = 'CLICK_SERVICE_CATEGORY',
  VIEW_SERVICE = 'VIEW_SERVICE',

  // Authentication Events
  LOGIN_INITIATED_HOMEPAGE = 'LOGIN_INITIATED_HOMEPAGE',
  LOGIN_ATTEMPT = 'LOGIN_ATTEMPT',
  LOGIN_ERROR = 'LOGIN_ERROR',
  SIGNUP_INITIATED_HOMEPAGE = 'SIGNUP_INITIATED_HOMEPAGE',
  SIGNUP_ATTEMPT = 'SIGNUP_ATTEMPT',
  ACCOUNT_CREATED = 'ACCOUNT_CREATED',
  VERIFY_ACCOUNT = 'VERIFY_ACCOUNT',
  RESET_PASSWORD = 'RESET_PASSWORD',

  // Application Form Events
  CANCEL_APPLICATION = 'CANCEL_APPLICATION',
  VIEW_APPLICATION_FORM = 'VIEW_APPLICATION_FORM',
  CONTINUE_APPLICATION = 'CONTINUE_APPLICATION',
  CONTINUE_APPLICATION_ERROR = 'CONTINUE_APPLICATION_ERROR',
  EDIT_APPLICATION = 'EDIT_APPLICATION',
  CREATE_APPLICATION = 'CREATE_APPLICATION',
  CREATE_APPLICATION_ERROR = 'CREATE_APPLICATION_ERROR',

  // Payment Events
  VIEW_BILL_ID = 'VIEW_BILL_ID',
  INITIATE_PAYMENT = 'INITIATE_PAYMENT',
  INITIATE_PAYMENT_ERROR = 'INITIATE_PAYMENT_ERROR',
  PAYMENT_METHOD_SELECTED = 'PAYMENT_METHOD_SELECTED',
  PAYMENT_INITIATED = 'PAYMENT_INITIATED',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  VIEW_APPLICATION_NUMBER = 'VIEW_APPLICATION_NUMBER',

  // Navigation Events
  REDIRECT_TO_GOV2 = 'REDIRECT_TO_GOV2',

  // Tracking Events
  TRACK_APPLICATION = 'TRACK_APPLICATION',
  SEARCH_APPLICATION = 'SEARCH_APPLICATION',
  SEARCH_APPLICATION_ERROR = 'SEARCH_APPLICATION_ERROR',

  // Certificate Events
  VERIFY_CERTIFICATE_INITIATED = 'VERIFY_CERTIFICATE_INITIATED',
  VERIFY_CERTIFICATE = 'VERIFY_CERTIFICATE',
  VERIFY_CERTIFICATE_ERROR = 'VERIFY_CERTIFICATE_ERROR',

  // Support Events
  VISIT_SUPPORT_PORTAL = 'VISIT_SUPPORT_PORTAL',

  // More Events can be added below
  // ...
}
