import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { KeycloakService } from 'keycloak-angular';
import { IService } from '../../models/services-list.model';
import { PublicServicesService } from '../../services/public-services.service';
import {
  AnalyticsEventType,
  AnalyticsService,
  EServicePriceType,
  ToastService,
} from '@irembo-andela/irembogov3-common';
import { Business } from '../../models/business/business.model';
import { UserBusinessService } from '../../services/user-business.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import {
  EApplicationApplicantType,
  EServiceApplicantType,
} from '../../models/applicant-type.enum';
import { IApplicationApplicantTypeAndBusinessInfo } from '../../models/applicant-type-and-business-id.model';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { IServiceTranslation } from '../../models/service/service-translation.model';

interface IBothApplicationtTypeOption {
  key: EApplicationApplicantType;
  label: string;
  description: string;
}
@Component({
  selector: 'irembogov-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceDetailsComponent implements OnInit, OnDestroy {
  keyword = '';
  serviceId = '';
  selectedService: IService | undefined;
  isLoggedIn?: boolean;
  isLoading = false;
  isLoadingTranslations = false;
  priceType = EServicePriceType;
  EApplicationApplicantType = EApplicationApplicantType;
  applicationApplicantTypeData?: IApplicationApplicantTypeAndBusinessInfo =
    undefined;
  serviceTranslations?: IServiceTranslation;

  navigatingToApplicationPage = false;

  userBusinesses?: Business[] = undefined;

  @ViewChild('noBusinessModal')
  noBusinessModal!: TemplateRef<unknown>;

  @ViewChild('selectApplicantForBusinessModal')
  selectApplicantForBusinessModal!: TemplateRef<unknown>;

  @ViewChild('selectApplicantForBothModal')
  selectApplicantForBothModal!: TemplateRef<unknown>;

  applicationApplicantTypeFormControl = new FormControl('');
  applicationBusinessIdFormControl = new FormControl('');
  applicationBusinessNameFormControl = new FormControl('');

  bothApplicationtTypeOptions: IBothApplicationtTypeOption[] = [
    {
      key: EApplicationApplicantType.INDIVIDUAL,
      label: 'Individual',
      description: 'Apply as a private citizen',
    },
    {
      key: EApplicationApplicantType.BUSINESS,
      label: 'Business',
      description: 'Apply on behalf of a company or organization',
    },
  ];

  private subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private keycloak: KeycloakService,
    private publicServicesService: PublicServicesService,
    private router: Router,
    private userBusinessService: UserBusinessService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService
  ) {
    this.applicationApplicantTypeData = undefined;
  }

  async ngOnInit() {
    this.isLoading = true;
    this.keyword = this.route.snapshot.queryParamMap.get('keyword') ?? '';
    this.serviceId = this.route.snapshot.queryParamMap.get('id') ?? '';
    this.isLoggedIn = await this.keycloak.isLoggedIn();

    if (this.isLoggedIn) {
      this.userBusinessService.fetchAndSetUserServices();
    }

    this.subscriptions.add(
      this.userBusinessService.userBusinessesSubjectObservable.subscribe(
        (userBusinesses: Business[] | undefined) => {
          this.userBusinesses = userBusinesses;
        }
      )
    );

    this.getServiceTranslation(
      localStorage.getItem('locale') ?? environment.DEFAULT_LOCALE
    );

    this.subscriptions.add(
      this.publicServicesService
        .getServiceById(this.serviceId)
        .subscribe(res => {
          this.selectedService = res;
          this.isLoading = false;

          if (this.selectedService) {
            this.analyticsService.trackEvent({
              eventType: AnalyticsEventType.VIEW_SERVICE,
              service_category_name: this.selectedService.category?.name,
              service_code: this.selectedService.serviceCode,
              service_name: this.selectedService.serviceName,
              institution_name: this.selectedService.organization?.name,
            });
          }
        })
    );

    this.subscriptions.add(
      this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
        this.getServiceTranslation(event.lang);
      })
    );
  }

  setSelectedBusiness(businessId: string, businessName: string): void {
    this.applicationBusinessIdFormControl.setValue(businessId);
    this.applicationBusinessNameFormControl.setValue(businessName);
  }

  openModal(
    content: unknown,
    windowClass: string,
    container?: string,
    backdrop?: boolean | 'static',
    size?: string
  ) {
    const options: NgbModalOptions = {
      ariaLabelledBy: 'verify-token',
      windowClass,
      centered: true,
      size,
      container,
      backdrop: backdrop ?? 'static',
    };
    return this.modalService.open(content, options);
  }

  loginUserAndRedirectBack(): void {
    this.keycloak.login({
      redirectUri: window.location.origin + '/',
      locale: localStorage.getItem('locale') ?? 'en-US',
    });
  }

  onApplyServiceClicked() {
    if (!this.isLoggedIn) {
      this.loginUserAndRedirectBack();
    }
    if (!this.selectedService) return;

    this.applicationApplicantTypeData = undefined;
    this.navigatingToApplicationPage = false;
    this.applicationApplicantTypeFormControl.setValue(null);
    this.applicationBusinessIdFormControl.setValue(null);
    this.applicationBusinessNameFormControl.setValue(null);

    if (this.selectedService?.applicantType === EServiceApplicantType.BOTH) {
      this.initSelectApplicantForBoth();
      return;
    }

    if (
      this.selectedService?.applicantType === EServiceApplicantType.BUSINESS
    ) {
      this.initSelectApplicantForBusiness();
      return;
    }

    this.applicationApplicantTypeData = {
      applicationApplicantType: EApplicationApplicantType.INDIVIDUAL,
    };

    //FOR SERVICE WITH APPLICANT TYPE INDIVIDUAL, ALWAYS OPEN APPLICATION PAGE
    this.navigateToApplicationPage();
  }

  initSelectApplicantForBoth(): void {
    this.openModal(
      this.selectApplicantForBothModal,
      'select-applicant-for-both-modal',
      '.irembogov-service-details'
    );
    return;
  }

  handleSelectedApplicantForBoth(): void {
    if (!this.applicationApplicantTypeFormControl.value) {
      return;
    }
    this.applicationApplicantTypeData = {
      applicationApplicantType: <EApplicationApplicantType>(
        this.applicationApplicantTypeFormControl.value
      ),
    };

    if (
      this.applicationApplicantTypeData.applicationApplicantType ===
      EApplicationApplicantType.INDIVIDUAL
    ) {
      this.navigateToApplicationPage();
      return;
    }
    this.modalService.dismissAll();
    this.initSelectApplicantForBusiness();
  }

  initSelectApplicantForBusiness(): void {
    if (
      this.userBusinesses === undefined ||
      this.userBusinesses?.length === 0
    ) {
      //NO BUSINESSES OPEN ADD BUSINESS MODAL
      this.modalService.dismissAll();
      this.openModal(
        this.noBusinessModal,
        'no-business-modal',
        '.irembogov-service-details'
      );

      return;
    }

    this.applicationApplicantTypeData = {
      applicationApplicantType: EApplicationApplicantType.BUSINESS,
    };
    this.openModal(
      this.selectApplicantForBusinessModal,
      'select-applicant-for-business-modal',
      '.irembogov-service-details'
    );
    return;
  }

  handleSelectedApplicantForBusiness(): void {
    if (
      !(
        this.applicationBusinessIdFormControl.value &&
        this.applicationBusinessNameFormControl.value
      )
    ) {
      this.toastService.show({
        body: 'Please select or add a business or organization.',
        type: 'error',
      });
      return;
    }
    this.applicationApplicantTypeData = {
      applicationApplicantType: EApplicationApplicantType.BUSINESS,
      businessInfo: {
        businessId: this.applicationBusinessIdFormControl.value,
        businessName: this.applicationBusinessNameFormControl.value,
      },
    };
    this.navigateToApplicationPage();
  }

  navigateToApplicationPage() {
    this.navigatingToApplicationPage = true;
    const navigationExtras: NavigationExtras = {
      queryParams: {
        keyword: this.selectedService?.serviceName,
        id: this.selectedService?.id,
      },
      state: {
        applicantData: {
          applicantType:
            this.applicationApplicantTypeData?.applicationApplicantType,
          businessId:
            this.applicationApplicantTypeData?.businessInfo?.businessId,
          businessName:
            this.applicationApplicantTypeData?.businessInfo?.businessName,
        },
      },
    };
    this.router.navigate(['/application'], navigationExtras);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  getServiceTranslation(locale: string) {
    this.isLoadingTranslations = true;

    this.subscriptions.add(
      this.publicServicesService
        .getServiceTranslationsByLocale(this.serviceId, locale)
        .subscribe({
          next: responses => {
            this.isLoadingTranslations = false;
            // initialize translations
            this.serviceTranslations = responses.data;
            this.translateService.setTranslation(locale, {
              ...this.translateService.translations[locale],
              ...(this.serviceTranslations[locale] as any),
            });
          },
          error: () => {
            this.isLoadingTranslations = false;
          },
        })
    );
  }
}
