<div
  *ngIf="error$ | async as error"
  class="global-error"
  [ngClass]="[to.layout === 'boxed' ? 'boxed' : 'plain', 'error']">
  <div class="error-content" [class.plain-content]="to.layout === 'plain'">
    <span class="error-message" [class.plain-message]="to.layout === 'plain'">
      {{ error.message }}
    </span>
  </div>
</div>
