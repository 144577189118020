import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AccountResendOtpRequestDto } from '../../../models/account-resend-otp-request.model';
import {
  RecipientType,
  UserType,
} from '../../../models/citizenCreationRequest.model';
import { AuthService } from '../service/auth.service';
import { finalize } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MixpanelService } from '../../../services/analytics/mixpanel.service';
import {
  AnalyticsAccountType,
  AnalyticsEventType,
} from '@irembo-andela/irembogov3-common';

@Component({
  selector: 'irembogov-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
})
export class ResetComponent implements OnInit {
  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private analyticsService: MixpanelService
  ) {}

  username = '';
  isLoading = false;
  errorMessage = '';
  hasError = false;
  resetType: 'email' | 'phone' = 'email';
  subHeading = '';
  resetForm!: FormGroup;
  locale = '';

  ngOnInit(): void {
    this.resetType = this.route.snapshot.queryParams['type'] || 'email';
    this.locale = localStorage.getItem('locale') ?? environment.locale;
    window.localStorage.setItem('flow', 'reset');
    this.subHeading = `No worries, enter the ${
      this.resetType === 'email' ? 'email' : 'phone number'
    } used to create your account and we'll send you instructions to reset your password`;

    this.resetForm = new FormGroup({
      username: new FormControl('', [
        Validators.required,
        this.resetType === 'email'
          ? Validators.email
          : Validators.pattern('[0-9]{10}'),
      ]),
    });
  }

  onFormSubmit(): void {
    this.isLoading = true;
    this.errorMessage = '';
    if (this.resetForm.invalid) {
      return;
    }
    this.username = this.resetForm.value.username;

    if (
      this.resetType === 'phone' &&
      /^[+]*[(]?\d{1,3}\)?[-\s\\./0-9]*$/g.test(this.username)
    ) {
      this.phoneFlow(this.username);
    } else {
      this.emailFlow(this.username);
    }
  }

  private emailFlow(email: string): void {
    const accountResendOtpRequestDto: AccountResendOtpRequestDto = {
      clientId: environment.authClientId,
      username: email,
      usernameType: RecipientType.EMAIL_ADDRESS,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };

    this.authService
      .initiatePasswordReset(accountResendOtpRequestDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.analyticsService.trackEvent({
            eventType: AnalyticsEventType.RESET_PASSWORD,
            account_type: AnalyticsAccountType.EMAIL,
          });
          this.router.navigate(['auth/reset-activation'], {
            queryParams: { email },
          });
        },
        error: (err: HttpErrorResponse) => {
          this.hasError = true;
          this.errorMessage = err.error.message || 'Fail to reset password';
        },
      });
  }

  private phoneFlow(phone: string): void {
    const accountResendOtpRequestDto: AccountResendOtpRequestDto = {
      clientId: environment.authClientId,
      username: phone,
      usernameType: RecipientType.PHONE_NUMBER,
      userType: UserType.CITIZEN,
      locale: this.locale,
    };

    this.authService
      .initiatePasswordReset(accountResendOtpRequestDto)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: () => {
          this.analyticsService.trackEvent({
            eventType: AnalyticsEventType.RESET_PASSWORD,
            account_type: AnalyticsAccountType.PHONE,
          });
          this.router.navigate(['auth/reset-password-otp'], {
            queryParams: { phone },
          });
        },
        error: (err: HttpErrorResponse) => {
          this.hasError = true;
          this.errorMessage = err.error.message || 'Fail to reset password';
        },
      });
  }
}
