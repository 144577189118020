import { FormlyFieldConfig } from '@ngx-formly/core';
import {
  IApplicationFormData,
  IFieldArrayData,
  IFieldData,
  IFieldGroupNameData,
} from '../../ui/models/application-form-data.model';
import { TDoubleIdentityFormControlValue } from '../../ui/models/citizen-document-types.enum';
import { IServiceTranslation } from '../../ui/models/service-translation.model';

interface IGetDatFieldsData {
  fieldGroup: IFieldData[];
  fieldArray?: IFieldArrayData[];
}

export const applicationFormBuildPreviewDataTranslated = (
  layout: FormlyFieldConfig[],
  layoutData: Record<string, unknown>,
  serviceTranslations?: IServiceTranslation,
  addLabelCount = false
): IApplicationFormData[] => {
  if (!layout[0].fieldGroup) {
    return [];
  }

  return layout[0].fieldGroup
    .filter(section => !section.hide || section.props?.['hideFromPreview'])
    .map(section => {
      const sectionName = section?.props?.label as string;
      const sectionKey = section?.key as string;
      const formGroup: IFieldGroupNameData[] = [];
      section.fieldGroup?.forEach(block => {
        if (block.props?.['hideFromPreview']) {
          return;
        }
        const fieldGroupName = block?.props?.label as string;
        const fieldGroupKey = block?.key as string;
        const fieldGroupData: IGetDatFieldsData = getFieldData(
          section,
          block,
          layoutData,
          serviceTranslations,
          addLabelCount
        );

        if (
          fieldGroupData.fieldGroup.length > 0 ||
          fieldGroupData?.fieldArray?.length
        ) {
          formGroup.push({
            fieldGroupName,
            fieldGroupKey,
            fieldGroup:
              fieldGroupData.fieldGroup.length > 0
                ? fieldGroupData.fieldGroup
                : undefined,
            fieldArray:
              fieldGroupData?.fieldArray?.length &&
              fieldGroupData.fieldArray.length > 0
                ? fieldGroupData.fieldArray
                : undefined,
          });
        }
      });
      return {
        formGroupName: sectionName,
        formGroupKey: sectionKey,
        formGroup,
      };
    })
    .filter(x => x.formGroup.length > 0);
};

const getFieldData = (
  section: FormlyFieldConfig,
  block: FormlyFieldConfig,
  layoutData: Record<string, unknown>,
  serviceTranslations?: IServiceTranslation,
  addLabelCount = false,
  isNestedBlock = false
): IGetDatFieldsData => {
  const fieldGroup: IFieldData[] = [];
  let fieldArray: IFieldArrayData[] | undefined = undefined;
  block.fieldGroup?.forEach((field, order) => {
    if (field.props?.['hideFromPreview']) {
      return;
    }

    let value = (
      (layoutData[section.key as string] as Record<string, unknown>)?.[
        block.key as string
      ] as Record<string, unknown>
    )?.[field.key as string];

    if (isNestedBlock) {
      value = (layoutData[block.key as string] as Record<string, unknown>)[
        field.key as string
      ];
    }

    if (!value) {
      return;
    }

    switch (field.type) {
      case 'customcascadingdropdowns':
        field?.props?.['configs'].forEach((ele: Record<string, unknown>) => {
          if ((value as Record<string, unknown>)[ele['key'] as string]) {
            fieldGroup.push({
              fieldType: 'customdropdown',
              fieldBindLabel: ele['bindlabel'],
              fieldName: ele['key'] as string,
              fieldLabel: ele['label'] as string,
              fieldParentKey: field['key'] as string,
              fieldValue: (value as Record<string, unknown>)[
                ele['key'] as string
              ],
              order,
            });
          }
        });
        break;
      case 'customslotbooking':
        field?.props?.['configs'].forEach((ele: Record<string, unknown>) => {
          if ((value as Record<string, unknown>)[ele['key'] as string]) {
            if (ele['type'] === 'number') {
              fieldGroup.push({
                fieldType: 'number',
                fieldBindLabel: '',
                fieldName: ele['key'] as string,
                fieldLabel: ele['label'] as string,
                fieldParentKey: field['parent'] as string,
                fieldValue: (value as Record<string, unknown>)[
                  ele['key'] as string
                ],
                order,
              });
            } else {
              fieldGroup.push({
                fieldType: 'customdropdown',
                fieldBindLabel: ele['bindlabel'],
                fieldName: ele['key'] as string,
                fieldLabel: ele['label'] as string,
                fieldParentKey: field['parent'] as string,
                fieldValue: (value as Record<string, unknown>)[
                  ele['key'] as string
                ],
                order,
              });
            }
          }
        });
        break;
      case 'customdoubleidinput':
        Object.keys(<TDoubleIdentityFormControlValue>value).forEach(key => {
          if ((<TDoubleIdentityFormControlValue>value)[key]) {
            fieldGroup.push({
              fieldType: 'customdoubleidinput',
              fieldBindLabel: '',
              fieldName: key,
              fieldLabel:
                (<TDoubleIdentityFormControlValue>value)[key]?.idLabel ?? '',
              fieldParentKey: field['key'] as string,
              fieldValue: (<TDoubleIdentityFormControlValue>value)[key],
              order,
            });
          }
        });
        break;
      case 'customrepeater': {
        const customRepeaterField: IFieldArrayData = {
          fieldArrayKey: field.key as string,
          arrayItems: [],
          order,
        };

        const fieldArrayGroup: FormlyFieldConfig[] =
          (<FormlyFieldConfig>field.fieldArray)?.fieldGroup ?? [];

        if (fieldArrayGroup.length > 0 && typeof value === 'object') {
          customRepeaterField.arrayItems = Object.keys(value).map(
            (_key, index: number) => {
              const fieldGroup: IFieldData[] = [];
              const formGroup: IFieldGroupNameData[] = [];
              const repeaterFieldValue = (<Record<string, unknown>>value)[
                index
              ];
              fieldArrayGroup.forEach((repeaterField: FormlyFieldConfig) => {
                switch (repeaterField.type) {
                  case 'block': {
                    const formGroupData = getNestedFields(
                      repeaterField,
                      addLabelCount,
                      index,
                      section,
                      repeaterFieldValue as Record<string, unknown>,
                      serviceTranslations,
                      index
                    );
                    if (formGroupData) {
                      formGroup.push(formGroupData);
                    }
                    break;
                  }
                  case 'customcascadingdropdowns': {
                    fieldGroup.push(
                      ...createLocationFields(
                        repeaterField?.props?.['configs'],
                        repeaterFieldValue,
                        repeaterField,
                        repeaterField['key'] as string,
                        index
                      )
                    );
                    break;
                  }
                  default: {
                    if (
                      (<Record<string, unknown>>repeaterFieldValue)[
                        repeaterField.key as string
                      ]
                    ) {
                      fieldGroup.push({
                        fieldType: repeaterField.type as string,
                        fieldBindLabel:
                          (repeaterField?.props?.['bindLabel'] as string) ?? '',
                        fieldName: repeaterField.key as string,
                        fieldLabel: (repeaterField?.props?.label +
                          setCountOnLabel(addLabelCount, index)) as string,
                        fieldValue: (<Record<string, unknown>>(
                          repeaterFieldValue
                        ))[repeaterField.key as string],
                        order: index,
                      });
                    }
                    break;
                  }
                }
              });
              return {
                fieldGroup,
                formGroup,
              };
            }
          );
        }

        if (!fieldArray) {
          fieldArray = [customRepeaterField];
          break;
        }

        fieldArray.push(customRepeaterField);
        break;
      }
      case 'radio':
      case 'checkbox':
      case 'select':
      case 'customdropdown': {
        if (!value || (value instanceof String && value?.length <= 0)) {
          break;
        }
        if (
          typeof value === 'string' &&
          value.length > 0 &&
          typeof value !== 'number'
        ) {
          const stringValue: any = value as string;
          const translatedValue =
            serviceTranslations?.[localStorage.getItem('locale') ?? 'en-US']?.[
              stringValue
            ];
          value = translatedValue ? translatedValue : value;
        }
        fieldGroup.push({
          fieldType: field.type as string,
          fieldBindLabel: getBindLabel(field),
          fieldName: field.key as string,
          fieldLabel: field?.props?.label as string,
          fieldValue: value,
          order,
        });
        break;
      }
      default:
        if (!value || (value instanceof String && value?.length <= 0)) {
          break;
        }
        fieldGroup.push({
          fieldType: field.type as string,
          fieldBindLabel: getBindLabel(field),
          fieldName: field.key as string,
          fieldLabel: field?.props?.label as string,
          fieldValue: value,
          order,
        });
        break;
    }
  });
  return { fieldGroup, fieldArray };
};

const getBindLabel = (field: FormlyFieldConfig): string | undefined => {
  if (
    !(
      field.type === 'customdropdown' ||
      field.type === 'customdropdownpaginated'
    )
  )
    return '';
  return field?.props?.['bindLabel'];
};

const setCountOnLabel = (setCount = false, index = 0) => {
  return setCount ? `#${index + 1}` : '';
};

const getNestedFields = (
  repeaterField: FormlyFieldConfig,
  addLabelCount: boolean,
  index: number,
  section: FormlyFieldConfig,
  layoutData: Record<string, unknown>,
  serviceTranslations?: IServiceTranslation,
  order?: number
): IFieldGroupNameData | null => {
  let nestedBlockData: IFieldData[] = [];
  if (repeaterField.fieldGroup) {
    nestedBlockData = getFieldData(
      section,
      repeaterField,
      layoutData,
      serviceTranslations,
      addLabelCount,
      true
    ).fieldGroup;
  }

  const fieldGroupData = {
    fieldGroupName: (repeaterField?.props?.label +
      setCountOnLabel(addLabelCount, index)) as string,
    fieldGroupKey: repeaterField.key as string,
    fieldGroup: nestedBlockData,
    order,
  };

  return fieldGroupData.fieldGroup.length > 0 ? fieldGroupData : null;
};

const createLocationFields = (
  configs: Record<string, unknown>[],
  value: unknown,
  field: FormlyFieldConfig,
  fieldParentKey: string,
  order?: number
): IFieldData[] => {
  return configs
    .map(ele => {
      const _value = (<Record<string, unknown>>value)[field.key as string];
      return {
        fieldType: 'customdropdown',
        fieldBindLabel: ele['bindlabel'] as string,
        fieldName: ele['key'] as string,
        fieldLabel: ele['label'] as string,
        fieldParentKey: fieldParentKey,
        fieldValue: _value
          ? (_value as Record<string, unknown>)[ele['key'] as string]
          : '',
        order,
      };
    })
    .filter(x => x.fieldValue);
};
