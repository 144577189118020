import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { EKeycloakPortalClientID } from '../../../auth/enums/keycloak-portal-client-id.enum';

@Component({
  selector: 'irembogov-privacy-policy',
  templateUrl: './irembogov-privacy-policy.component.html',
  styleUrls: ['./irembogov-privacy-policy.component.scss'],
})
export class IrembogovPrivacyPolicyComponent {
  constructor(private readonly keycloak: KeycloakService) {}
  COMPANY_TEXT =
    '<b>Company</b> (referred to as either &quot;the Company&quot;, &quot;We&quot;, &quot;Us&quot;, or &quot;Our&quot; in this Agreement) refers to Irembo Ltd, Irembo Campus KG 9 Ave, Nyarutarama, Kigali, Rwanda.';
  GET_WEBSITE_TEXT() {
    const clientId = this.keycloak.getKeycloakInstance().clientId;
    return `<b>Website</b> refers to ${
      clientId == EKeycloakPortalClientID.PRIVATE_PORTAL
        ? 'IremboPlus'
        : 'IremboGov'
    }, accessible from <a href=&quot;https://${
      clientId == EKeycloakPortalClientID.PRIVATE_PORTAL
        ? 'iremboplus.com'
        : 'irembo.gov.rw'
    }&quot;>https://${
      clientId == EKeycloakPortalClientID.PRIVATE_PORTAL
        ? 'iremboplus.com'
        : 'irembo.gov.rw'
    }</a>`;
  }
}
