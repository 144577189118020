<div
  ngbDropdown
  class="nav-item profile m-0 px-4 pe-2 d-flex align-items-center"
  placement="bottom-end">
  <div
    class="dropdown-toggle profile_dropdown d-flex {{ dropDownCustomCss }}"
    id="profile_dropdown"
    ngbDropdownToggle>
    <div
      class="profile_info d-flex flex-column align-items-end"
      *ngIf="showUserDetails">
      <b class="m-0 p-0 username"> {{ username }} </b>
      <small class="m-0 p-0 activeRole"> {{ activeRole }} </small>
    </div>
    <div class="profile-img-section">
      <img
        *ngIf="updatedProfile"
        [src]="updatedProfile"
        alt=""
        class="rounded-circle float-start img-fluid font-weight-bold" />
      <div
        *ngIf="!updatedProfile"
        class="rounded-circle img-thumbnail img-fluid font-weight-bold text-dark text-center letter-holder">
        <strong style="font-size: 24px">{{ userNameFirstLetter }}</strong>
      </div>
    </div>
  </div>
  <ul
    ngbDropdownMenu
    aria-labelledby="profile_dropdown"
    class="w-100"
    style="min-width: 240px">
    <li
      class="d-flex align-items-start px-3 justify-content-between align-content-start">
      <div class="">
        <img
          *ngIf="updatedProfile"
          class="rounded-circle"
          [src]="updatedProfile"
          height="54"
          width="54"
          alt="" />
        <div
          *ngIf="!updatedProfile"
          class="rounded-circle img-thumbnail img-fluid font-weight-bold text-dark text-center letter-holder">
          <strong style="font-size: 24px">{{ userNameFirstLetter }}</strong>
        </div>
      </div>
      <div class="ps-2 d-flex dropdown-user-details">
        <h2
          *ngIf="showDropdownEmail"
          class="col-12 text-capitalize"
          style="font-size: 14px; font-weight: 600; color: #344054">
          {{ username }}
        </h2>
        <h2
          *ngIf="showDropdownUserDetails"
          class="mb-0 p-0 text-capitalize"
          style="font-size: 14px; font-weight: 600; color: #344054">
          {{ username }}
        </h2>
        <small
          *ngIf="showDropdownUserDetails"
          class="m-0 p-0 activeRole text-dark">
          {{ activeRole }}
        </small>
        <p
          class="text-grey"
          style="font-size: 14px"
          *ngIf="agentNationalId !== undefined">
          {{ agentNationalId }}
        </p>
      </div>
    </li>
    <hr class="dropdown-divider" />
    <li class="" (click)="profileClick.emit()" *ngIf="showProfileLink">
      <a class="dropdown-item text-grey">
        <img src="assets/images/user-01.svg" alt="" class="me-2" />
        {{ profileLinkText | translate }}
      </a>
    </li>

    <li
      class=""
      *ngFor="let navItem of navItems"
      (click)="navItemClick.emit(navItem.action)">
      <a class="dropdown-item d-flex align-items-center text-grey">
        <i class="{{ navItem.icon }} me-2" *ngIf="navItem.icon"></i>
        {{ navItem.name | translate }}
      </a>
    </li>
    <ng-container *ngIf="showDropdownRoles">
      <li *ngIf="roles !== undefined && roles.length > 1">
        <a class="dropdown-item">
          <i class="fa fa-user-tie me-2"></i>
          {{ 'Roles' | translate }}
        </a>
        <a
          class="d-block dropdown-item no-icon"
          (click)="roleChange.emit(role)"
          *ngFor="let role of roles">
          <span class="badge rounded-pill text-bg-irembo-blue">
            {{ role }}
          </span>
        </a>
      </li>
    </ng-container>
    <a class="dropdown-item text-grey" (click)="logout.emit()">
      <img src="assets/images/log-out-01.svg " alt="" class="me-2" />
      {{ 'Logout' | translate }}
    </a>
  </ul>
</div>
