<ng-container
  *ngTemplateOutlet="
    loadingFile ? loading : !file ? noFile : hasFile
  "></ng-container>

<ng-template #noFile>
  <div
    [ngClass]="{ 'cta-drag-enter': addDragClass }"
    class="file-upload-container noFile"
    (drop)="toogledragClass($event, false)"
    (dragenter)="toogledragClass($event, true)"
    (dragleave)="toogledragClass($event, false)">
    <input
      (click)="onClickFileUpload($event)"
      [accept]="acceptList()"
      type="file"
      (change)="change($event)"
      class="uploader file-upload-input"
      [disabled]="disabled" />

    <div
      class="generic-file-uploader {{
        file ? 'hasFile flex-row align-items-center' : 'flex-column'
      }} border p-3 rounded d-flex align-items-center w-100">
      <div
        class="upload-icon d-flex justify-content-center align-items-center mb-2">
        <i class="fa-solid fa-cloud-arrow-up text-muted"></i>
      </div>
      <div>
        <button
          *ngIf="allowUploadPreviousFiles"
          type="button"
          class="btn btn-link text-decoration-none cta-btn file-upload-input-label"
          (click)="selectFile(selectfile)">
          {{ noFileActionLabel | translate }}
          <span
            class="text-center"
            [innerHTML]="noFileSubLabelHtml"
            class="mb-2"></span>
        </button>
        <div class="text-center" *ngIf="!allowUploadPreviousFiles">
          <input
            class="file-upload-input"
            [accept]="acceptList()"
            [id]="id + '_generic'"
            (change)="change($event)"
            type="file"
            [disabled]="disabled" />
          <label
            class="file-upload-input-label text-primary"
            [for]="id + '_generic'"
            >{{ noFileActionLabel | translate }}</label
          >&nbsp;
          <span *ngIf="!file" [innerHTML]="noFileSubLabelHtml"></span>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #hasFile>
  <div class="file-upload-container generic position-relative">
    <ng-container
      *ngTemplateOutlet="genericUploaderBasicTemplate"></ng-container>
    <div
      *ngIf="validatePhoto && failedChecks.length > 0"
      class="invalid-feedback photo-validation-errors"
      style="display: block">
      <div>
        <span style="font-size: 24px; font-weight: bold"
          >Sorry, your photo is not a compliant passport photo.</span
        ><br />
        Make sure that:
        <ul>
          <li>The passport photo is on a white background</li>
          <li>
            The face is positioned in the center for both vertical and
            horizontal directions
          </li>
          <li>Eyes are on the same position and looking straight forward</li>
        </ul>
        Please check this article on our knowledge base for more details:
        <a
          href="https://support.irembo.gov.rw/en/support/solutions/articles/47001165759-how-to-apply-for-an-e-passport-application"
          >click here</a
        >
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="file-upload-container">
    <div class="preview">
      <div class="preview-info">
        <div class="file-icon">
          <i class="fa fa-file-text"></i>
        </div>
        <div class="file-details">
          <h1 class="fw-semibold">
            {{ loadingFileName }}
          </h1>
          <div class="progress progress-container">
            <div
              class="progress-bar"
              role="progressbar"
              [attr.aria-valuenow]="progress | async"
              [style.width]="(progress | async) + '%'"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectfile let-modal>
  <div class="modal-header border-bottom-0">
    <div class="modal-title float-start" id="exampleModalLabel">
      <h5 id="exampleModalLabel">
        {{ 'Upload document' | translate }}
      </h5>
      <p>
        {{ 'Choose from existing documents or upload a new one.' | translate }}
      </p>
    </div>
    <button
      type="button bg-transparent text-secondary"
      class="btn-close float-end"
      aria-label="Close"
      (click)="modal.close('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div
      class="nav nav-tabs w-100 hstack gap-3 text-center"
      id="nav-tab"
      role="tablist">
      <button
        type="button"
        class="nav-link fw-bold flex-grow-1 text-center"
        id="nav-attachments"
        data-bs-toggle="tab"
        data-bs-target="#upload-new"
        type="button"
        role="tab"
        aria-controls="upload-new"
        aria-selected="false">
        {{ 'Upload from device' | translate }}
      </button>
      <button
        type="button"
        class="nav-link fw-bold flex-grow-1 text-center"
        id="nav-certificates"
        data-bs-toggle="tab"
        data-bs-target="#certificates"
        type="button"
        role="tab"
        aria-controls="certificates"
        aria-selected="true"
        (click)="resetState('certificate')">
        {{ 'My certificates' | translate }}
      </button>
      <button
        type="button"
        class="nav-link fw-bold active flex-grow-1 text-center"
        id="nav-attachments"
        data-bs-toggle="tab"
        data-bs-target="#attachments"
        type="button"
        role="tab"
        aria-controls="attachments"
        aria-selected="false"
        (click)="resetState('document')">
        {{ 'Previous uploaded documents' | translate }}
      </button>
    </div>
    <div class="tab-content my-4" id="nav-tabContent">
      <div
        class="tab-pane fade my-3"
        id="upload-new"
        role="tabpanel"
        aria-labelledby="nav-upload-new-tab">
        <div
          [ngClass]="{ 'cta-drag-enter': addDragClass }"
          class="file-upload-container noFile generic"
          (drop)="toogledragClass($event, false)"
          (dragenter)="toogledragClass($event, true)"
          (dragleave)="toogledragClass($event, false)">
          <input
            (click)="onClickFileUpload($event)"
            [accept]="acceptList()"
            type="file"
            (change)="change($event)"
            class="uploader file-upload-input"
            [disabled]="disabled" />

          <ng-container
            *ngTemplateOutlet="genericUploaderBasicTemplate"></ng-container>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="certificates"
        role="tabpanel"
        aria-labelledby="nav-certificates-tab">
        <div
          class="table-rounded-corners table-responsive custom-table certificateAttachmentsContainer"
          (scroll)="onCertificateAttachmentScroll()">
          <table
            class="table table-sm table-bordered table-striped rounded-table"
            id="results-table"
            aria-describedby="list of user applications">
            <thead>
              <tr class="table-secondary">
                <th class="icon-column" scope="col">
                  <input
                    type="checkbox"
                    name="certificate"
                    [(ngModel)]="selectAllCertificateAttachments"
                    (change)="toggleSelectAllAttachments('certificate')" />
                </th>
                <th scope="col">{{ 'File name' | translate }}</th>
                <th scope="col">{{ 'Date uploaded' | translate }}</th>
                <th scope="col">{{ 'Last updated' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cert of certificateAttachments; let i = index">
                <td class="file-select">
                  <input
                    type="checkbox"
                    name="certificate"
                    [(ngModel)]="cert['selected']"
                    (change)="selectAttchmentFile('certificate', i, $event)" />
                </td>
                <td class="file-name">
                  {{ cert['displayName'] }}
                </td>
                <td>
                  {{ castToString(cert.dateCreated) | date : 'MMM d, y' }}
                </td>
                <td>
                  {{
                    castToString(cert.certificateExpiration) | date : 'MMM d, y'
                  }}
                </td>
              </tr>
              <tr *ngIf="certificateAttachments.length === 0">
                <td colspan="3">{{ 'No certificates' | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        class="tab-pane fade show active"
        id="attachments"
        role="tabpanel"
        aria-labelledby="nav-attachments-tab">
        <div
          class="table-rounded-corners table-responsive custom-table previousAttachmentsContainer"
          (scroll)="onPreviousAttachmentScroll()">
          <table
            class="table table-sm table-bordered table-striped rounded-table"
            aria-describedby="list of user applications attachments">
            <thead>
              <tr class="table-secondary">
                <th class="icon-column" scope="col">
                  <input
                    type="checkbox"
                    name="attachment"
                    [(ngModel)]="selectAllPreviousAttachments"
                    (change)="toggleSelectAllAttachments('document')" />
                </th>
                <th scope="col">{{ 'File name' | translate }}</th>
                <th scope="col">{{ 'Date uploaded' | translate }}</th>
                <th scope="col">{{ 'Last updated' | translate }}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let attachment of previousAttachments; let i = index">
                <td class="file-select">
                  <input
                    type="checkbox"
                    name="certificate"
                    [(ngModel)]="attachment['selected']"
                    (change)="selectAttchmentFile('document', i, $event)" />
                </td>
                <td class="file-name">{{ attachment['displayName'] }}</td>
                <td>
                  {{
                    castToString(attachment['dateCreated']) | date : 'MMM d, y'
                  }}
                </td>
                <td>
                  {{
                    castToString(attachment['dateCreated']) | date : 'MMM d, y'
                  }}
                </td>
              </tr>
              <tr *ngIf="previousAttachments.length === 0">
                <td colspan="2">{{ 'No previous attachments' | translate }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="hstack gap-2 mx-auto w-100">
      <button
        type="button "
        class="btn cancel-button w-100"
        aria-label="Close"
        (click)="modal.close('Cross click')">
        {{ 'Cancel' | translate }}
      </button>
      <button
        type="button"
        class="btn btn-primary w-100"
        (click)="uploadSelectedFiles()"
        [disabled]="isUploading || !readyToUpload">
        <div *ngIf="isUploading" class="spinner-border spinner-border-sm"></div>
        {{ 'Upload selected file' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #genericUploaderBasicTemplate>
  <div
    class="generic-file-uploader {{
      file ? 'hasFile flex-row align-items-center' : 'flex-column'
    }} border p-3 rounded d-flex align-items-center w-100 text-center">
    <div
      class="upload-icon d-flex justify-content-center align-items-center mb-2">
      <i
        class="fa-solid {{
          file
            ? 'fa-regular fa-file text-primary'
            : 'fa-cloud-arrow-up text-muted'
        }}"></i>
    </div>
    <div class="w-100">
      <ng-container *ngIf="file">
        <button
          type="button"
          class="btn btn-link p-0 remove-file-button text-muted"
          ngbTooltip="Remove file"
          (click)="removeFile()">
          <i class="fa-regular fa-trash-alt" aria-hidden="true"></i>
        </button>
        <div class="fw-semibold text-start" [ngClass]="{ hasErros: hasErrors }">
          <span *ngIf="file?.fileName" class="text-start">
            {{
              file.fileName.length < 25
                ? file.fileName
                : (file.fileName | slice : 0 : 25) + '...'
            }}
          </span>
          <span class="text-danger" *ngIf="hasErrors">
            <i class="fa fa-times-circle"></i>
          </span>
        </div>
        <div class="text-start">
          {{ formatFileSizeBytesUtil(file?.fileSize) | uppercase }}
        </div>
        <div
          class="progress-container d-flex flex-row justify-content-between align-items-center w-100">
          <div class="progress w-100">
            <div
              class="progress-bar"
              role="progressbar"
              [attr.aria-valuenow]="100"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
          <div class="ms-1">100%</div>
        </div>
      </ng-container>
      <ng-container *ngIf="!file">
        <input
          class="file-upload-input"
          [accept]="acceptList()"
          [id]="id"
          (change)="change($event)"
          type="file"
          [disabled]="disabled" />
        <label
          class="file-upload-input-label text-primary text-center"
          [for]="id"
          >{{ noFileActionLabel | translate }}</label
        >&nbsp;
        <span *ngIf="!file" [innerHTML]="noFileSubLabelHtml"></span>
      </ng-container>
    </div>
  </div>
</ng-template>
